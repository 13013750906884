import { useRecoilValue, useSetRecoilState } from "recoil";
import { locationsState } from "../atom";
import api from "../../Api/api";



const useLocations = () => {
    const locations = useRecoilValue(locationsState);
    const setLocations = useSetRecoilState(locationsState);

    const addLocation = async (formData) => {

        if (!formData) {
            throw new Error('Prencha todos os campos obrigatórios!');
        }

        const response = await api.post("/locations", formData);

        if (response.data.status === 'success') {
            const id = response.data.item.id;
            setLocations(previousState => [...previousState, { ...formData, id }]);
        } else {
            throw new Error(response.data.message);
        }

    }


    const editLocation = async (formData) => {

        const response = await api.put("/locations", formData);

        if (response.data.status === 'success') {
            setLocations(previousState => {
                const index = previousState.findIndex(address => address.id == formData?.id);
                return [...previousState.slice(0, index), formData, ...previousState.slice(index + 1)];
            })
        } else {
            throw new Error(response.data.message);
        }

    }


    const removeLocation = async (id) => {

        if (!id) {
            throw new Error('Informe o código do endereço');
        }

        const response = await api.delete(`/locations/${id}`);

        if (response.data.status === 'success') {
            setLocations(previousState => previousState.filter(address => address.id !== id))
        } else {
            throw new Error(response.data.message);
        }

    }


    return { addLocation, editLocation, removeLocation, locations };
}





export default useLocations;
