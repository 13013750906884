import { Modal } from "antd";
import "../../../UI/Loading.scss"
import { useEffect, useState } from "react";
import loading_1 from '../../../Assets/loading/loading_1.svg';
import loading_2 from '../../../Assets/loading/loading_2.svg';
import loading_3 from '../../../Assets/loading/loading_3.svg';
import loading_4 from '../../../Assets/loading/loading_4.svg';
import loading_5 from '../../../Assets/loading/loading_5.svg';
import loading_6 from '../../../Assets/loading/loading_6.svg';
import loading_7 from '../../../Assets/loading/loading_7.svg';

export default function Loading({ onCancel, show }) {

    const loadingImages = [
        loading_1,
        loading_2,
        loading_3,
        loading_4,
        loading_5,
        loading_6,
        loading_7
    ];

    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        if (show) {
            const intervalId = setInterval(() => {
                setImageIndex((prevIndex) => (prevIndex + 1) % loadingImages.length);
            }, 150); // Intervalo de troca de imagem (500ms)

            return () => clearInterval(intervalId);
        }
    }, [show, loadingImages.length]);

    return (
        <Modal
            className="loading-modal transparent-background"
            centered
            open={show}
            closeIcon={false}
            onCancel={() => { }}
            footer={null}
            width={120}
            style={{ backgroundColor: 'transparent' }} // Define o estilo do modal inteiro, incluindo o background


        >
            <div className="loading-container">
                <img src={loadingImages[imageIndex]} alt="Loading..." />
            </div>
        </Modal>
    );
};

