import { selector } from 'recoil';
import api from '../../Api/api';

export const loadProducts = selector({
    key: 'loadProducts',
    get: async () => {
        try {
            const response = await api.get("/articles");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});


export const loadLocations = selector({
    key: 'loadLocations',
    get: async () => {
        try {
            const response = await api.get("/locations");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});

export const loadLocationMovements = selector({
    key: 'loadLocationMovements',
    get: async () => {
        try {
            const response = await api.get("/location_movements");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});

export const loadStockMovements = selector({
    key: 'loadStockMovements',
    get: async () => {
        try {
            const response = await api.get("/stock_movements");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});


export const loadLocationBalances = selector({
    key: 'loadLocationBalances',
    get: async () => {
        try {
            const response = await api.get("/location_balances");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});

export const loadBalances = selector({
    key: 'loadBalances',
    get: async () => {
        try {
            const response = await api.get("/balances");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});

export const loadMarketplaces = selector({
    key: 'loadMarketplaces',
    get: async () => {
        try {
            const response = await api.get("/marketplaces");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});

export const loadMPAccounts = selector({
    key: 'loadMPAccounts',
    get: async () => {
        try {
            const response = await api.get("/MPAccounts");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});

export const loadAds = selector({
    key: 'loadAds',
    get: async () => {
        try {
            const response = await api.get("/ads");
            if (response.data.status === 'success') {
                return response.data.items;
            } else {
                return [];
            }
        } catch (error) {
            console.log(error.message);
            return [];
        }
    }
});