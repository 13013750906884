import { Modal } from 'antd';
import "../../../../UI/Common/Modal/ModalText.scss"



const ModalText = ({ message, show, onCancel }) => {

  return (
    <>
      <Modal
        style={{ padding: 5, fontFamily: "Roboto" }}
        centered
        footer={null}
        open={show}
        closable={true}
        onCancel={() => onCancel(prev => !prev)}
      >
        <div style={{ display: 'flex' , margin: 5}}>
          {message}
        </div>
      </Modal>
    </>
  );
};
export default ModalText;