import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Space, Form, Row, Col, Card } from "antd";
import ButtonsCard from "../../Common/Buttons/ButtonsCard";
import useLocations from "../../../State/Hooks/useLocations";
import ModalText from "../../Common/Modal/ModalText";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import DefaultCard from "../../Common/DefaultCard";
import Loading from "../../Common/Loading";

const LocationForm = () => {
    const { addLocation, editLocation, locations } = useLocations();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    let location = {};

    if (id) location = locations.find((location) => location.id == id);

    const navigate = useNavigate();

    const saveHandler = async (formData) => {
        setIsSaving(prev => !prev);
        try {
            if (id) {
                await editLocation({ ...formData, id });
            } else {
                await addLocation(formData);
            }
            navigate("/locationList");
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message);
        }
        setIsSaving(prev => !prev);
    };

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }

    return (
        <Form
            initialValues={location}
            name={id ? "editLocation" : "addLocation"}
            form={form}
            layout="vertical"
            onFinish={saveHandler}
        >
            <div className="site-space-compact-wrapper">
                <Space direction="vertical" size="middle" style={{ display: "flex", width: "70%", marginBottom: 16 }}>
                    <DefaultCard title={id ? "Editar Endereço" : "Novo Endereço"} size="large" >

                        <Row gutter={24}>
                            <Col xl={{ span: 12 }}>
                                <Form.Item name={"name"} label={"Nome"} rules={[{ required: true, message: "Campo obrigatório" }]}>
                                    <DefaultInput name="name" placeHolder="Digite..." maxLength={100} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </DefaultCard>
                </Space>

                <Form.Item>
                    <ButtonsCard id={id} onClickCancel={() => navigate("/locationList")} link={"/locationList"} />
                </Form.Item>
            </div>
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isSaving} />
        </Form>
    );
};

export default LocationForm;



