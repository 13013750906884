import { Input } from "antd";
import '../../../../UI/Common/Inputs/DefaultInput.scss'

export default function DefaultInput({
    value,
    prefix,
    name,
    placeHolder,
    maxLength,
    disabled,
    inputType,
    onChange,
    keyboardType,
    type,
    onFocus,
    style,
    onBlur
}) {

    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                name,
                value: event.target.value,
            },
        });
    };

    if (inputType === "password") {
        return (
            <Input.Password
                name={name}
                value={value}
                placeholder={placeHolder}
                onChange={handleChange}
                type={type}
                maxLength={maxLength}
                className="default-input"
                style={style}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        );
    } else if (inputType === "textArea") {
        return (
            <Input.TextArea
                rows={3}
                name={name}
                value={value}
                placeholder={placeHolder}
                onChange={handleChange}
                maxLength={maxLength}
                className="default-input"
                style={style}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        );
    } else {
        return (
            <Input
                name={name}
                value={value}
                placeholder={placeHolder}
                maxLength={maxLength}
                disabled={disabled ?? false}
                prefix={prefix}
                onChange={handleChange}
                type={type}
                className="default-input"
                keyboardtype={keyboardType}
                style={style}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        );
    }

}



