import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
// const api = axios.create({ baseURL: 'http://localhost:3001' });

export const defaultHeaders = () => {
    const token = localStorage.getItem("token");
    const headers = {}

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return headers
};


api.interceptors.request.use(
    async (config) => {
        config.headers = { ...config.headers, ...defaultHeaders() };
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default api;