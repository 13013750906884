import { Card } from "antd";
import '../../../UI/Common/DefaultCard.scss'

export default function DefaultCard({ children, title, size, headStyle, white }) {


    return (
        <>
            <Card
                title={title}
                size={size}
                className={white ? "white-card" : "default-card"}
                headStyle={headStyle ?? {
                    backgroundColor: '#F2F5F8', // Cor de fundo do cabeçalho
                    color: '#5A646E', // Cor do texto do cabeçalho
                    fontWeight: 600,
                    fontSize: 18,
                    borderBottom: "1px solid #CCD1D6",
                    fontFamily: "Roboto"
                }}
                bodyStyle={{
                    fontFamily: "Roboto",
                    color: "#0E2C35",
                    marginTop: white ? -20 : -15
                }}
            >
                {children}
            </Card>
        </>
    );
}



