import { Image, Form, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../State/Hooks/useAuth";

import logo_logistiko from '../../../../Assets/logo_logistiko.svg'
import '../../../../UI/Common/Background.scss'
import '../../../../UI/Pages/Login/ForgotPasswordScreen.scss'
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultInput from "../../Common/Inputs/DefaultInput";


const ForgotPasswordScreen = () => {
  const { authState, forgotPassword } = useAuth();
  const [sentMessage, setSentMessage] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const changePasswordHandler = async (email) => {
    try {
      const success = await forgotPassword(email);
      if (success) {
        setSentMessage('Enviamos um e-mail para redefinição da senha para você!')
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validateEmail = (rule, value, callback) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regexEmail.test(value);

    if (isValid) {
      return callback();
    } else {
      return callback('Campo preenchido incorretamente');
    }
  }



  
  return (
    <div className="background">
      <div className="background-img">
        <Row>
          <Col>
            <div className="handle-forgot-password-screen">
              <Image src={logo_logistiko} preview={false} />
              <h1 className="handle-h1-forgot-password-screen">Redefinir senha</h1>

              {sentMessage
                ? (
                  <>
                    <h2 className="handle-h2-forgot-password-screen">
                      {sentMessage}
                    </h2>

                    <DefaultButton label={"Fazer Login"} onClick={() => { navigate("/"); window.location.reload(); }} />
                  </>
                )
                : (
                  <>
                    <h2 className="handle-h2-forgot-password-screen">
                      Digite seu <strong style={{ fontWeight: 'bold' }}>e-mail abaixo</strong> para receber um link de redefinição de senha!
                    </h2>
                    <Form
                      title="Redefinir senha"
                      name="forgot password"
                      className="form-forgot-password-screen"
                      onFinish={(values) => changePasswordHandler(values)}
                      layout="vertical"
                    >
                      <Form.Item label="Email" name="email" rules={[{ /*required: true,*/ message: "Informe seu e-mail!" }, { validator: validateEmail }]}>
                        <DefaultInput prefix={<UserOutlined className="site-form-item-icon" />} placeHolder="exemplo@gmail.com" />
                      </Form.Item>
                      {authState.loginErrorMessage && (<p style={{ fontSize: 12, color: '#FF3541', marginBottom: -5 }}>{authState.loginErrorMessage}</p>)}
                      <Form.Item>
                        <DefaultButton disabled={buttonDisabled} htmlType="submit" label={"ENVIAR E-MAIL"} onPress={() => setButtonDisabled(true)} />
                      </Form.Item>
                      <p className="handle-p-forgot-password-screen">Não tem cadastro?&nbsp;
                        {<Link to='/register' className="handle-link-sign-login-screen" onClick={() => Location.reload()}>Cadastre-se</Link>}
                      </p>
                    </Form>
                  </>
                )
              }

            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
