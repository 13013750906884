import { atom } from "recoil";
import {
  loadLocations,
  loadStockMovements,
  loadProducts,
  loadMPAccounts,
  loadMarketplaces,
  loadLocationBalances,
  loadBalances,
  loadAds
} from "./Selectors";

export const productsState = atom({
  key: 'products',
  default: loadProducts
});

export const locationsState = atom({
  key: 'locations',
  default: loadLocations
});

export const locationMovementsState = atom({
  key: 'locationMovements',
  default: []
});

export const locationBalancesState = atom({
  key: 'locationBalances',
  default: loadLocationBalances
});

export const balancesState = atom({
  key: 'balances',
  default: loadBalances
});

export const MPAccountsState = atom({
  key: 'MPAccounts',
  default: loadMPAccounts
});

export const marketPlacesState = atom({
  key: 'marketPlaces',
  default: loadMarketplaces
});

export const stockMovementsState = atom({
  key: 'stockMovements',
  default: loadStockMovements
});

export const authorizationState = atom({
  key: 'authState',
  default: { token: null, loginErrorMessage: "", subscription: null }
});

export const adsState = atom({
  key: 'adsState',
  default: loadAds
});

