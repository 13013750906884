import api from '../../Api/api';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authorizationState } from "../atom";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const authState = useRecoilValue(authorizationState);
  const setAuthState = useSetRecoilState(authorizationState);
  const navigate = useNavigate();


  const tryLocalSignin = async () => {
    if (!authState.token) {
      const token = localStorage.getItem("token");

      if (token) {
        try {
          setAuthState({ token, loginErrorMessage: "", subscription: null });
          const getSub = async () => {
            const subscription = await getSubscription(token);
            setAuthState({ token, loginErrorMessage: "", subscription });
          }
          getSub();
        } catch (error) {
          setAuthState((prev) => ({ ...prev, loginErrorMessage: 'Não foi possível verificar assinatura' }));
        }
      }
    };
  };


  const signin = async ({ email, password }) => {
    try {
      const response = await api.post("/signin", { email, password });
      if (response.data.status === 'success') {
        const token = response.data.token;
        localStorage.setItem("token", token);

        const subscription = await getSubscription(token);

        setAuthState({ token, loginErrorMessage: "", subscription });
        window.location.reload();
      } else {
        setAuthState((prev) => ({ ...prev, loginErrorMessage: response.data.message, subscription: null }));
      }
    } catch (error) {
      setAuthState((prev) => ({ ...prev, loginErrorMessage: error.response.data.error, subscription: null }));
    }
  };



  const getSubscription = async (token) => {
    try {
      const response = await api.get("/getSubscription", {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.status === 'success') {
        return response.data.subscription;
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  };

  const signout = () => {
    navigate("/");
    localStorage.clear();
    window.location.reload();
    setAuthState((prev) => ({ ...prev, loginErrorMessage: "" }));
  };


  const forgotPassword = async (email) => {
    try {
      const response = await api.post("/forgot-password", email);
      if (response.data.status === 'success') {
        setAuthState((prev) => ({ ...prev, loginErrorMessage: "" }));
        return true;
      } else {
        setAuthState((prev) => ({ ...prev, loginErrorMessage: response.data.message }));
        return false;
      }
    } catch (error) {
      setAuthState((prev) => ({ ...prev, loginErrorMessage: error.response.data.error }));
      return false;
    }
  };


  const changePassword = async (email, password, code) => {
    try {
      await api.post("/change-password", { email, password, code });
      navigate("/");
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      setAuthState((prev) => ({ ...prev, loginErrorMessage: error.response.data.error }));
    }
  };

  const newRegister = async (account) => {
    try {
      const response = await api.post("/register", { ...account, company: account.email });
      if (response.data.status === 'success') {
        setAuthState({ loginErrorMessage: "" });
        return true;
      } else {
        setAuthState((prev) => ({ ...prev, loginErrorMessage: response.data.message }));
        return false;
      }
    } catch (error) {
      setAuthState((prev) => ({ ...prev, loginErrorMessage: error?.response?.data?.error || "Ops! Ocorreu um erro, tente novamente mais tarde." }));
      return false;
    }
  };

  const resendEmailVerification = async (email) => {
    try {
      const response = await api.post("/resendEmailVerification", { email });
      if (response.data.status === 'success') {
        setAuthState({ loginErrorMessage: "" });
      } else {
        setAuthState((prev) => ({ ...prev, loginErrorMessage: response.data.message }));
      }
    } catch (error) {
      setAuthState((prev) => ({ ...prev, loginErrorMessage: error?.response?.data?.error }));
      return false;
    }
  }

  const clearLoginErrorMessage = () => setAuthState((prev) => ({ ...prev, loginErrorMessage: "" }));


  return {
    authState,
    tryLocalSignin,
    signin,
    signout,
    clearLoginErrorMessage,
    newRegister,
    forgotPassword,
    changePassword,
    resendEmailVerification
  };

};


export default useAuth;
