import { useRecoilValue, useSetRecoilState } from "recoil";
import { productsState } from "../../State/atom";
import api from "../../Api/api";

const useProducts = () => {
    const products = useRecoilValue(productsState);
    const setProducts = useSetRecoilState(productsState);

    const addProduct = async (formData) => {

        if (!formData.sku || !formData.name) {
            throw new Error('Prencha todos os campos obrigatórios!');
        }

        const response = await api.post("/articles", formData);

        if (response.data.status === 'success') {
            const id = response.data.item.id;
            setProducts(previousState => [...previousState, { ...formData, id }]);
        } else {
            throw new Error(response.data.message);
        }

    }


    const editProduct = async (formData) => {

        if (!formData?.sku) {
            throw new Error('Prencha todos os campos obrigatórios!');
        }

        const response = await api.put("/articles", formData);

        if (response.data.status === 'success') {
            setProducts(previousState => {
                const index = previousState.findIndex(product => product.id == formData?.id);
                return [...previousState.slice(0, index), formData, ...previousState.slice(index + 1)];
            })
        } else {
            throw new Error(response.data.message);
        }

    }


    const removeProduct = async (id) => {

        if (!id) {
            throw new Error('Informe o id do produto');
        }

        const response = await api.delete(`/articles/${id}`);

        if (response.data.status === 'success') {
            setProducts(previousState => previousState.filter(product => product.id !== id))
        } else {
            throw new Error(response.data.message);
        }

    }

    return { addProduct, editProduct, removeProduct, products };
}



export default useProducts;