import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import LinkButton from "../../Common/Buttons/LinkButton";
import ModalButtons from "../../Common/Modal/ModalButtons";
import useProducts from "../../../State/Hooks/useProducts";
import ModalText from "../../Common/Modal/ModalText";
import useAuth from "../../../State/Hooks/useAuth";
import DefaultTable from "../../Common/DefaultTable";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import plus_white from "../../../../Assets/plus_white.svg"
import trash from "../../../../Assets/modal/trash.svg"
import { ReactComponent as SearchIcon } from "../../../../Assets/search_icon.svg";
import Loading from "../../Common/Loading";
import DefaultIcon from "../../Common/DefaultIcon";

const ProductList = () => {
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState("");
    const [id, setId] = useState();
    const navigate = useNavigate();

    const { products, removeProduct } = useProducts();

    const { authState } = useAuth();

    const onChangeFilter = (event) => setFilter(event.target.value);

    const onDeleteHandler = (id) => { setShowModalDelete(true); setId(id); };

    const confirmDeleteHandler = async () => {
        setShowModalDelete(false);
        setIsLoading(prev => !prev);
        try {
            await removeProduct(id);
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message);
        }
        setIsLoading(prev => !prev);
    };

    const columns = [
        { title: "SKU", dataIndex: "sku", sorter: (a, b) => a.sku?.localeCompare(b.sku), },
        { title: "Nome", dataIndex: "name", sorter: (a, b) => a.name?.localeCompare(b.name), },
        { title: "Descrição", dataIndex: "description", sorter: (a, b) => a.description?.localeCompare(b.description), },
        { title: "Controla Estoque", dataIndex: "has_stock_control", render: (has_stock_control) => (has_stock_control ? "Sim" : "Não") },
        { title: "Expira", dataIndex: "has_expiration_date", render: (has_expiration_date) => (has_expiration_date ? "Sim" : "Não") },
        {
            title: " ",
            width: '4%',
            render: (_, record) =>
                <div style={{ display: 'flex', gap: 5 }} >
                    <DefaultIcon tooltip={"Editar"} icon={<EditOutlined />} onClick={() => navigate(`/productEdit/${record.id}`)} />
                    <DefaultIcon tooltip={"Excluir"} icon={<DeleteOutlined />} onClick={() => onDeleteHandler(record.id)} />
                </div>
        },
    ];

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }


    const isButtonActive = () => authState.subscription.data.plan.qtd_articles > products.length;

    //filter
    useEffect(() => {
        let updatedState = [...products];
        if (filter.length > 0) updatedState = updatedState.filter((item) =>
            item?.sku?.toString().toLowerCase().includes(filter)
            || item?.name?.toLowerCase().includes(filter.toLowerCase()));
        setFilteredList(updatedState)
    }, [filter, products]);

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row gutter={24} style={{ justifyContent: "space-between", marginBottom: -20 }}>
                    <Col xl={{ span: 8 }}>
                        <DefaultInput name="filter" placeHolder="Nome do Produto ou SKU" onChange={onChangeFilter} prefix={<SearchIcon />} />
                    </Col>
                    <Col xl={{ span: 3 }} />
                    <Col xl={{ span: 8 }} />
                    <Col xl={{ span: 5 }}>
                        <LinkButton disabled={!isButtonActive()} link={`/productEdit/`} label={"Cadastrar Produto"} icon={plus_white} />
                    </Col>
                </Row>
                <p></p>
                <DefaultTable scroll={{ x: "100%" }} columns={columns} dataSource={filteredList} rowKey={"id"} />
            </Space>
            {
                showModalDelete && (
                    <ModalButtons
                        textCancel={"Cancelar"}
                        textConfirm={"Sim, Excluir"}
                        headerText={"Excluir produto"}
                        message={"Tem certeza que deseja excluir este cadastro? Uma vez excluído, não poderá ser recuperado."}
                        onCancel={() => setShowModalDelete(false)}
                        onConfirm={confirmDeleteHandler}
                        show={showModalDelete}
                        id={1}
                        redConfirm={true}
                        icon={trash}
                    />
                )
            }
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isLoading} />
        </>
    );
};

export default ProductList;
