import { useRecoilValue, useSetRecoilState } from "recoil";
import { locationBalancesState } from "../atom";
import api from "../../Api/api";

const useLocationBalances = () => {
    const locationBalances = useRecoilValue(locationBalancesState);
    const setLocationBalances = useSetRecoilState(locationBalancesState);

    const loadLocationBalances = async () => {
        const response = await api.get("/location_balances");
        if (response.data.status === 'success') setLocationBalances(response.data.items);
    }

    return { loadLocationBalances, locationBalances };
}



export default useLocationBalances;
