import { useRecoilValue, useSetRecoilState } from "recoil";
import { stockMovementsState } from "../atom";
import api from "../../Api/api";

const useStockMovements = () => {
    const stockMovements = useRecoilValue(stockMovementsState);
    const setStockMovements = useSetRecoilState(stockMovementsState);

    const reloadStockMovements = async () => {
        const response = await api.get("/stock_movements");
        if (response.data.status === 'success') setStockMovements(response.data.items);
    }

    const addStock = async (formData) => {

        if (!formData) {
            throw new Error('Prencha todos os campos obrigatórios!');
        }

        const response = await api.post("/stock_in", formData);

        if (response.data.status === 'success') {
            reloadStockMovements();
        } else {
            throw new Error(response.data.message);
        }
    };


    const reserveStock = async (formData) => {

        if (!formData) {
            throw new Error('Prencha todos os campos obrigatórios!');
        }

        const response = await api.post("/stock_out", formData);

        if (response.data.status === 'success') {
            reloadStockMovements();
        } else {
            throw new Error(response.data.message);
        }
    };


    const consumeStock = async (formData) => {
        const response = await api.post("/confirmStockOut", formData);

        if (response.data.status === 'success') {
            reloadStockMovements();
        } else {
            throw new Error(response.data.message);
        }
    };



    return { addStock, reserveStock, consumeStock, setStockMovements, reloadStockMovements, stockMovements };
}



export default useStockMovements;
