import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import LinkButton from "../../Common/Buttons/LinkButton";
import ModalButtons from "../../Common/Modal/ModalButtons";
import useLocations from "../../../State/Hooks/useLocations";
import ModalText from "../../Common/Modal/ModalText";
import DefaultTable from "../../Common/DefaultTable";
import plus_white from '../../../../Assets/plus_white.svg';
import trash from '../../../../Assets/modal/trash.svg';
import DefaultInput from "../../Common/Inputs/DefaultInput";
import { ReactComponent as SearchIcon } from "../../../../Assets/search_icon.svg";
import Loading from "../../Common/Loading";
import DefaultIcon from "../../Common/DefaultIcon";

const LocationList = () => {
    const { removeLocation, locations } = useLocations();
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [id, setId] = useState();
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [filter, setFilter] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const onDeleteHandler = (id) => { setShowModalDelete(true); setId(id); };

    const onChangeFilter = (event) => setFilter(event.target.value);

    const confirmDeleteHandler = async () => {
        setIsSaving(prev => !prev);
        setShowModalDelete(false);
        try {
            await removeLocation(id);
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message);
        }
        setIsSaving(prev => !prev);
    };

    const columns = [
        { title: "Armazem", dataIndex: "warehouse_id", render: () => "Default" },
        { title: "Nome", dataIndex: "name", sorter: (a, b) => a.name?.localeCompare(b.name), },
        {
            title: " ",
            width: '4%',
            render: (_, record) =>
                <div style={{ display: 'flex', gap: 5 }} >
                    <DefaultIcon tooltip={"Editar"} icon={<EditOutlined />} onClick={() => navigate(`/locationEdit/${record.id}`)} />
                    <DefaultIcon tooltip={"Excluir"} icon={<DeleteOutlined />} onClick={() => onDeleteHandler(record.id)} />
                </div>
        },
    ];

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }

    //filter
    useEffect(() => {
        let updatedState = locations.filter((item) => item.name !== 'Default');
        if (filter.length > 0) updatedState = updatedState.filter((item) => item?.name?.toLowerCase().includes(filter.toLowerCase()));
        setFilteredList(updatedState);
    }, [filter, locations]);

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row gutter={24} style={{ justifyContent: "space-between", marginBottom: -20 }}>
                    <Col xl={{ span: 8 }}>
                        <DefaultInput name="filter" placeHolder="Nome do Endereço" onChange={onChangeFilter} prefix={<SearchIcon />} />
                    </Col>
                    <Col xl={{ span: 8 }}></Col>
                    <Col xl={{ span: 2 }} />
                    <Col xl={{ span: 5 }}>
                        <LinkButton link={`/locationEdit/`} label={"Cadastrar Endereço"} icon={plus_white} />
                    </Col>
                </Row>
                <p></p>
                <DefaultTable scroll={{ x: "100%" }} columns={columns} dataSource={filteredList} rowKey={"id"}/>
            </Space>
            {
                showModalDelete && (
                    <ModalButtons
                        textCancel={"Cancelar"}
                        textConfirm={"Sim, Excluir"}
                        headerText={"Excluir Endereço"}
                        message={"Tem certeza que deseja excluir este cadastro? Uma vez excluído, não poderá ser recuperado."}
                        onCancel={() => setShowModalDelete(false)}
                        onConfirm={confirmDeleteHandler}
                        show={showModalDelete}
                        id={1}
                        redConfirm={true}
                        icon={trash}
                        link={""}
                    />
                )
            }
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isSaving} />
        </>
    );
};

export default LocationList;
