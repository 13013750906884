import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select, Space } from 'antd';
import DefaultTable from '../../Common/DefaultTable';
import EditableCell from '../../Common/Inputs/EditableCell';
import { ReactComponent as SearchIcon } from "../../../../Assets/search_icon.svg";
import useMarketPlaces from '../../../State/Hooks/useMarketPlaces';
import useProducts from '../../../State/Hooks/useProducts';
import { CloseOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import DefaultIcon from '../../Common/DefaultIcon';
import DefaultInput from '../../Common/Inputs/DefaultInput';
import DefaultDropdown from '../../Common/Inputs/DefaultDropdown';
import useAds from '../../../State/Hooks/useAds';
import api from '../../../Api/api';
import ModalText from '../../Common/Modal/ModalText';
import Loading from '../../Common/Loading';
import { useNavigate } from 'react-router-dom';
import ModalButtons from '../../Common/Modal/ModalButtons';
import ReloadButton from '../../Common/Buttons/ReloadButton';

const updateSkuMessage1 = `Se o SKU informado ainda não estiver cadastrado no Logistiko, sugerimos que você faça o cadastro primeiro. Caso confirme assim mesmo, o SKU do anúncio será trocado, mas o anúncio não estará sincronizado com o Logistiko.`
const updateSkuMessage2 = `Se o SKU já estiver cadastrado e o controle de estoque estiver ativado, o estoque do anúncio será atualizado conforme o saldo disponível no Logistiko.`


const AdList = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [alterData, setAlterData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isEditing = (record) => record.id === editingKey;
  const { marketplaces } = useMarketPlaces();
  const { products } = useProducts();
  const { ads, reloadAds } = useAds();
  const { Option } = Select;

  const navigate = useNavigate();

  const sendErrorMessage = (message) => {
    setErrorMessage(message);
    setShowModalError(true);
  }

  const edit = (record) => {
    form.setFieldsValue({ sku: record.sku });
    setEditingKey(record.id);
  };

  useEffect(() => {
    if (editingKey) {
      const sku = form.getFieldValue('sku');
      setIsSaveButtonDisabled(!sku);
    }
  }, [form, editingKey]);

  const handleValuesChange = (changedValues, allValues) => {
    if ('sku' in changedValues) {
      setIsSaveButtonDisabled(!changedValues.sku);
    }
  };

  const onConfirmHandler = (data) => { setShowModalConfirm(true); setAlterData(data); };

  const updateSku = async () => {
    setShowModalConfirm(false);
    setIsLoading(prev => !prev);
    const { id, item_id, account_id, variation_id } = alterData;
  
    try {
      const row = await form.validateFields();
  
      const data = {
        item_id,
        account_id,
        sku: row.sku,
        variation_id
      }
  
      const response = await api.put('/updateSku', data);
      
      if (response.data.isUpdated) {
        setIsLoading(prev => !prev);
        const newData = [...filteredList];
        const index = newData.findIndex((item) => item.id === id);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setFilteredList(newData);
        setEditingKey('');
      } else {
        setEditingKey('');
        setIsLoading(prev => !prev);
        sendErrorMessage(`Ops! Houve um erro e o SKU não foi atualizado! Verifique se o anúncio ${item_id} está ativo!`);        
      }
    } catch (error) {
      setIsLoading(prev => !prev);
      
      if (error.response && error.response.data.message) {
        sendErrorMessage(error.response.data.message);
      } else {
        sendErrorMessage(`Ops! Houve um erro e o SKU não foi atualizado! Verifique se o anúncio ${item_id} está ativo!`);
      }
    }
  };  

  const columns = [
    { title: 'Conta', dataIndex: 'nickname', width: '4%', },
    { title: 'Título', dataIndex: 'title', width: '27%', },
    { title: 'Marketplace', dataIndex: 'marketplace_id', width: '11%', render: (mp_id) => marketplaces?.find(mp => mp.id === mp_id)?.name },
    { title: 'Anúncio', dataIndex: 'item_id', width: '7%', },
    { title: 'Status', dataIndex: 'status', width: '8%', },
    { title: 'Link', dataIndex: 'link', width: '4%', render: (link) => <a href={link} target="_blank" rel="noopener noreferrer">Acessar</a> },
    { title: 'Estoque', dataIndex: 'quantity', width: '4%', },
    { title: 'Preço', dataIndex: 'price', width: '11%', render: (price) => `R$ ${Number(price).toFixed(2)}` },
    { title: 'Variação', dataIndex: 'variation_id', width: '6%' },
    {
      title: 'SKU',
      dataIndex: 'sku',
      width: '14%',
      editable: true,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: 'sku',
        title: 'Sku',
        editing: isEditing(record),
      }),
    },
    {
      title: ' ',
      width: '4%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div style={{ display: 'flex', gap: 5 }} >
            <DefaultIcon tooltip={"Salvar"} icon={<SaveOutlined />} onClick={() => onConfirmHandler(record)} disabled={isSaveButtonDisabled} />
            <DefaultIcon tooltip={"Cancelar"} icon={<CloseOutlined />} onClick={() => setEditingKey('')} />
          </div>
        ) : (
          <div style={{ display: 'flex', gap: 5, justifyContent: 'center' }} >
            {record.status !== 'Em revisão' && (<DefaultIcon tooltip={"Editar SKU"} icon={<EditOutlined />} onClick={() => edit(record)} disabled={editingKey !== ''} />)}
            {(!products.find(p => p.sku === record.sku) && record.sku) && (<DefaultIcon tooltip={"Cadastrar SKU"} icon={<PlusOutlined />} onClick={() => navigate('/productEdit', { state: { adSku: record.sku, adTitle: record.title } })} />)}
          </div>
        );
      },
    },
  ];

  //filter
  useEffect(() => {
    let updatedState = [...ads];
    if (filter?.length > 0) updatedState = updatedState.filter((item) =>
      item?.title?.toLowerCase().includes(filter.toLowerCase())
      || item?.item_id?.toLowerCase().includes(filter.toLowerCase())
      || item?.nickname?.toLowerCase().includes(filter.toLowerCase())
      || item?.sku?.toLowerCase().includes(filter.toLowerCase()));

    if (statusFilter?.length > 0) updatedState = updatedState.filter((item) =>
      item?.status?.toLowerCase().includes(statusFilter.toLowerCase()));

    setFilteredList(updatedState);
  }, [filter, ads, statusFilter]);

  const statusOptions = [
    { name: 'Ativo' },
    { name: 'Pausado' },
    { name: 'Em revisão' },
    { name: 'Fechado' },
  ]

  const onChangeFilter = (event) => setFilter(event.target.value);

  const getRowClassName = (record) => {
    if (!products.find((p => p.sku === record.sku))) {
      return 'custom-row-red';
    }
    return '';
  };

  const reloadList = async () => {
    setIsLoading(prev => !prev);
    try {
      await reloadAds();
    } catch (error) {
      sendErrorMessage(`Ops! Não foi possível atualizar a lista. Tente novamente mais tarde!`);
    }
    setIsLoading(prev => !prev);
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row gutter={24} style={{ justifyContent: "space-between", marginBottom: -20 }}>
          <Col span={8}>
            <DefaultInput name="filter" placeHolder="Título, Anúncio, Conta ou SKU" onChange={onChangeFilter} prefix={<SearchIcon />} />
          </Col>
          <Col span={3}>
            <DefaultDropdown placeHolder="Status" optionFilterProp={'label'} onChange={(value) => setStatusFilter(value)}>
              {statusOptions.map((option) => (
                <Option key={option.name} label={option.name} value={option.name}>
                  {option.name}
                </Option>
              ))}
            </DefaultDropdown>
          </Col>
          <Col span={3}>
            <ReloadButton onClick={reloadList} />
          </Col>
          <Col span={10} />
        </Row>
        <p></p>
        <Form form={form} component={false} onValuesChange={handleValuesChange}>
          <DefaultTable
            components={{ body: { cell: EditableCell } }}
            bordered
            dataSource={filteredList}
            columns={columns}
            getRowClassName={getRowClassName}
            pagination={{ onChange: () => setEditingKey('') }}
            rowKey="id"
          />
        </Form>
      </Space>
      <ModalButtons
        textCancel={"Cancelar"}
        textConfirm={"Confirmar"}
        headerText={"Atenção!"}
        message={updateSkuMessage1}
        message2={updateSkuMessage2}
        onCancel={() => { setShowModalConfirm(false); setEditingKey(''); }}
        onConfirm={updateSku}
        show={showModalConfirm}
        id={1}
      // icon={trash}
      />
      <ModalText
        message={errorMessage}
        show={showModalError}
        onCancel={setShowModalError}
      />
      <Loading show={isLoading} />
    </>


  );
};

export default AdList;
