import { Select } from "antd";
import '../../../../UI/Common/Inputs/DefaultDropdown.scss'

export default function DefaultDropdown({
    children,
    optionFilterProp,
    onChange,
    placeHolder,
    showSearch
}) {

    return (
        <Select
            className="default-dropdown"
            placeholder={placeHolder}
            optionFilterProp={optionFilterProp}
            onChange={onChange}
            allowClear
            showSearch
        >
            {children}
        </Select>
    );
}



