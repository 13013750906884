import { Image, Form, Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../State/Hooks/useAuth";
import { useState } from "react";

import DefaultInput from "../../Common/Inputs/DefaultInput";
import DefaultButton from "../../Common/Buttons/DefaultButton";

import logo_logistiko from '../../../../Assets/logo_logistiko.svg'
import '../../../../UI/Common/Background.scss'
import '../../../../UI/Pages/Login/ResetPasswordScreen.scss'

const ResetPasswordScreen = () => {
  const { authState, changePassword } = useAuth();
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  const email = params.get('email');
  const code = params.get('code');

  const changePasswordHandler = async ({ newPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      setErrorMessage(`As senhas devem ser iguais!`);
      return
    }

    try {
      await changePassword(email, newPassword, code);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const validatePasswordComplexity = (rule, value, callback) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasMin = value.length > 8;
    if (!(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar && hasMin)) {
      callback('A senha deve conter pelo menos 8 digitos, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.');
    } else {
      callback();
    }
  };

  return (
    <div className="background">
      <div className="background-img">
        <Row>
          <Col>
            <div className="handle-reset-password-screen">
              <Image src={logo_logistiko} preview={false} />
              <div className="handle-form-reset-password-screen">
                <h1 className="handle-h1-reset-password-screen">Redefinir senha</h1>
                <h2 className="handle-h2-reset-password-screen">Crie uma nova senha abaixo!</h2>
                <Form
                  title="Redefinir Senha"
                  name="reset-password"
                  className="form-reset-password-screen"
                  onFinish={(values) => { setErrorMessage(null); changePasswordHandler(values); }}
                  layout="vertical"
                >
                  <Form.Item name={"newPassword"} label={"Nova Senha"} rules={[{ validator: validatePasswordComplexity }]}>
                    <DefaultInput name="newPassword" placeHolder="********" maxLength={120} inputType={"password"} />
                  </Form.Item>
                  <Form.Item name={"confirmPassword"} label={"Repetir Nova Senha"} rules={[{ min: 8, message: "Valor inválido.", },]}>
                    <DefaultInput name="confirmPassword" placeHolder="********" maxLength={120} style={{ marginBottom: '10px' }} inputType={"password"} />
                  </Form.Item>
                  {authState.loginErrorMessage && (<p style={{ fontSize: 12, color: '#FF3541' }}>{authState.loginErrorMessage}</p>)}
                  {errorMessage && (<p style={{ fontSize: 12, color: '#FF3541' }}>{errorMessage}</p>)}
                  <Form.Item>
                    <DefaultButton htmlType="submit" label={"REDEFINIR SENHA"} />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
