import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Space, Form, Select, Row, Col } from "antd";
import ButtonsCard from "../../Common/Buttons/ButtonsCard";
import ModalText from "../../Common/Modal/ModalText";
import useProducts from "../../../State/Hooks/useProducts";
import useLocations from "../../../State/Hooks/useLocations";
import useStockMovements from "../../../State/Hooks/useStockMovements";
import useLocationBalances from "../../../State/Hooks/useLocationBalances";
import EditableCell from "../../Common/Inputs/EditableCell";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import DefaultCard from "../../Common/DefaultCard";
import useBalances from "../../../State/Hooks/useBalances";
import plus from "../../../../Assets/plus.svg"
import DefaultTable from "../../Common/DefaultTable";
import DefaultDropdown from "../../Common/Inputs/DefaultDropdown";
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import Loading from "../../Common/Loading";
import DefaultIcon from "../../Common/DefaultIcon";

// - -feito no salvar apenas- validar quantidade para nao passar do maximo permitido pelo produto ao clicar pra adicionar localizacao ou quando for editar uma ja existente

const StockMovementForm = () => {
    const { id } = useParams();
    const { Option } = Select;
    const { locations } = useLocations();
    const { products } = useProducts();
    const { addStock, reserveStock } = useStockMovements();
    const { loadLocationBalances } = useLocationBalances();
    const { loadBalances } = useBalances();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [showModalError, setShowModalError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [isEditingLocation, setIsEditingLocation] = useState(false);
    const [quantityLeft, setQuantityLeft] = useState(0);
    const [movementType, setMovementType] = useState(1);

    const movementTypes = [
        { key: 1, name: 'Entrada' },
        { key: 2, name: 'Saída' },
    ]

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }
    const saveHandler = async (formData) => {
        setIsSaving(prev => !prev);
        if (movementType === 1) {
            //entrada
            const updatedFormData = {
                ...formData,
                buy_id: null,
                quantity: +formData.totalQuantity,
                locations: selectedLocations
            };
            const isValid = validateTotalQuantity();
            if (isValid) {
                try {
                    await addStock(updatedFormData);
                    navigate("/stockMovementList");
                    loadBalances();
                    loadLocationBalances();
                } catch (error) {
                    sendErrorMessage(error.response.data.message);
                }
            } else {
                sendErrorMessage('A soma das quantidades endereçadas deve ser igual a quantidade total informada na entrada.');
            }
        }
        if (movementType === 2) {
            //saida
            const updatedFormData = { ...formData, quantity: +formData.totalQuantity };

            try {
                await reserveStock(updatedFormData);
                navigate("/stockMovementList");
                loadBalances();
                loadLocationBalances();
            } catch (error) {
                sendErrorMessage(error.response.data.message);
            }
        }

        setIsSaving(prev => !prev);
    }


    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ loc_id: '', quantity: '', ...record });
        setIsEditingLocation(true);
        setEditingKey(record.key);
    };

    const deleteLocation = (record) => {
        setIsEditingLocation(false);
        setSelectedLocations(prevLoc => prevLoc.filter(loc => loc.key !== record.key));
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...selectedLocations];
            const index = newData.findIndex((item) => key === item.key);
            const newItem = {
                key,
                loc_id: row.loc_id,
                quantity: row.quantity,
            };

            if (index > -1) {
                newData.splice(index, 1, newItem);
            } else {
                newData.push(newItem);
            }

            setSelectedLocations(newData);
            setEditingKey('');
            setIsEditingLocation(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const cancel = () => {
        setIsEditingLocation(false);
        setSelectedLocations(prev => prev.filter(x => x.key !== editingKey));
        setEditingKey('');
    };


    const columns = [
        { title: 'Endereço', dataIndex: 'loc_id', width: '25%', editable: true, render: (_, record) => locations?.find(x => x.id == record.loc_id)?.name },
        { title: 'Quantidade', dataIndex: 'quantity', width: '15%', editable: true },
        {
            title: ' ',
            width: '4%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div style={{ display: 'flex', width: 30, gap: 5 }} >
                        <DefaultIcon tooltip={"Salvar"} icon={<SaveOutlined />} onClick={() => save(record.key)} />
                        <DefaultIcon tooltip={"Cancelar"} icon={<CloseOutlined />} onClick={cancel} />
                    </div>
                ) : (
                    <div style={{ display: 'flex', width: 30, gap: 5 }} >
                        <DefaultIcon tooltip={"Editar"} icon={<EditOutlined />} onClick={() => edit(record)} disabled={editingKey !== ''} />
                        <DefaultIcon tooltip={"Excluir"} icon={<DeleteOutlined />} onClick={() => deleteLocation(record)} disabled={editingKey !== ''} />
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'quantity' ? 'number' : 'dropdown',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const addLocationHandler = () => {
        const newData = {
            key: Date.now(),
            loc_id: '',
            quantity: 0,
        };
        setSelectedLocations([...selectedLocations, newData]);
        edit(newData);
    };


    const initialValues = {
        movementType: 1,
        sku: 'TESTE_ALEX_1',
        quantity: 1
    }

    const availableLocations = locations.filter(loc =>
        !selectedLocations.some(selectedLoc => selectedLoc.loc_id === loc.id)
    );




    const validateTotalQuantity = () => {
        if (selectedLocations.length > 0) {
            const totalQuantityForm = form.getFieldValue('totalQuantity');
            const totalCount = selectedLocations.reduce((total, loc) => total + +loc.quantity, 0);

            return totalCount === +totalQuantityForm ? true : false;
        } else {
            return true;
        }
    };

    return (
        <>
            <Form
                initialValues={initialValues}
                name={"AddStockMovementList"}
                form={form}
                layout="vertical"
                onFinish={saveHandler}
                className="form"
            >
                <Space direction="vertical" size="middle" style={{ display: "flex", width: "70%", marginBottom: 16 }}>
                    <DefaultCard title={"Novo Movimento"} size="large" >

                        <Row gutter={24}>


                            <Col span={12}>
                                <Form.Item name={"movementType"} label={"Tipo de Movimento"} rules={[{ required: true, message: 'Campo obrigatório', }]}>
                                    <DefaultDropdown placeHolder="Selecione..." optionFilterProp={'label'} onChange={(value) => setMovementType(value)}>
                                        {movementTypes.map((option) => (
                                            <Option key={option.key} label={option.name} value={option.key}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </DefaultDropdown>
                                </Form.Item>
                            </Col>



                            <Col span={12}>
                                <Form.Item name={"sku"} label={"SKU"} rules={[{ required: true, message: 'Campo obrigatório', }]}>
                                    <DefaultDropdown placeHolder="Selecione..." optionFilterProp={'label'} showSearch={true} >
                                        {products.filter(p => p.has_stock_control === true).map((option) => (
                                            <Option key={option.sku} label={option.sku + ' - ' + option.name} value={option.sku}>
                                                {option.sku + ' - ' + (option.name ?? '')}
                                            </Option>
                                        ))}
                                    </DefaultDropdown>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name={"totalQuantity"}
                                    label={"Quantidade"}
                                    rules={[
                                        { required: true, message: 'Campo obrigatório' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!isNaN(parseInt(value)) && isFinite(value) && parseInt(value) > 0) { return Promise.resolve(); }
                                                return Promise.reject(new Error('Insira um número válido.'));
                                            },
                                        }),]}>
                                    <DefaultInput name="totalQuantity" placeHolder="Digite..." maxLength={15} keyboardType="numeric" />
                                </Form.Item>
                            </Col>

                            {/* <Col  span={12}  >
                                <p>
                                    Quantidade a distribuir: {quantityLeft}
                                </p>
                            </Col> */}

                            <Col span={12}>
                                <Form.Item name={"document_number"} label={"Nota Fiscal"} >
                                    <DefaultInput name="document_number" placeHolder="Digite..." maxLength={15} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name={"obs"} label={"Observação"}>
                                    <DefaultInput name="obs" placeHolder="Digite..." maxLength={240} inputType={"textArea"} />
                                </Form.Item>
                            </Col>
                        </Row>


                        {movementType === 1 && (
                            <>
                                <DefaultButton
                                    onClick={addLocationHandler}
                                    disabled={isEditingLocation}
                                    label={"Adicionar Endereçamento"}
                                    style={{ marginBottom: 16 }}
                                    white={true}
                                    prefix={plus}
                                />

                                {selectedLocations?.length > 0 && (
                                    <DefaultTable
                                        components={{ body: { cell: (props) => <EditableCell {...props} data={availableLocations} /> } }}
                                        bordered
                                        dataSource={selectedLocations}
                                        columns={mergedColumns}
                                        pagination={false}
                                        style={{ width: "80%" }}
                                        rowKey={"key"}
                                    />
                                )}
                            </>
                        )}

                    </DefaultCard>
                </Space>

                <Form.Item>
                    <ButtonsCard id={id} onClickCancel={() => navigate("/stockMovementList")} link={"/stockMovementList"} disabled={isEditingLocation} />
                </Form.Item>

            </Form>

            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isSaving} />
        </>
    );
};


export default StockMovementForm;



