import './App.css';
import { RecoilRoot } from 'recoil';
import ResolveAuth from './Components/Main/Pages/Login/ResolveAuth';
import { Route, Routes } from "react-router-dom";
import PricingList from './Components/Main/Pages/Pricing/PricingList';
import Payment from './Components/Main/Pages/Pricing/Payment';
import PrivacyPolicy from './Components/Main/Pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './Components/Main/Pages/TermsOfUse/TermsOfUse';


const App = () => {

  return (
    <RecoilRoot>
      <Routes>
        <Route path="/pricing" element={<PricingList />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsOfUse" element={<TermsOfUse />} />
        <Route exact path="*" element={<ResolveAuth />} />
      </Routes>
    </RecoilRoot>
  );


};
export default App;
