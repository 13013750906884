import useAuth from "../../State/Hooks/useAuth";
import useBalances from "../../State/Hooks/useBalances";
import useMPAccounts from "../../State/Hooks/useMPAccounts";
import useProducts from "../../State/Hooks/useProducts";
import IndexBox from "../Common/Home/IndexBox";
// import useLocations from "../../State/Hooks/useLocations";
import '../../../UI/Common/Main.scss'
import DefaultCard from "../Common/DefaultCard";
import DefaultButton from "../Common/Buttons/DefaultButton";
import InvoiceBox from "../Common/Home/InvoiceBox";
import useStockMovements from "../../State/Hooks/useStockMovements";
import api from "../../Api/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";
import ModalText from "../Common/Modal/ModalText";
import ModalButtons from "../Common/Modal/ModalButtons";
import trash from "../../../Assets/modal/trash.svg";

const Main = () => {
    const navigate = useNavigate();
    const [invoiceList, setInvoiceList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModalDelete, setShowModalDelete] = useState(false);
    const { authState } = useAuth();
    const { products } = useProducts();
    const { balances } = useBalances();
    const { accounts } = useMPAccounts();
    const { stockMovements } = useStockMovements();
    // const { locations } = useLocations();

    // const totalLocations = locations.filter(x => x.name != "Default")?.length ?? 0;
    const qtd_accounts = authState?.subscription?.data?.plan?.qtd_accounts ?? 0;
    const totalAccounts = accounts?.filter(x => x.status === true)?.length ?? 0;
    const qtd_articles = authState?.subscription?.data?.plan?.qtd_articles ?? 0;
    const totalProducts = products?.length ?? 0;
    const productsAvailables = balances?.filter(x => x.available > 0)?.length ?? 0;
    const totalHasStockControl = products?.filter(x => x.has_stock_control === true)?.length ?? 0;
    const dayOrders = filterCurrentDayRecords(stockMovements)?.length ?? 0;
    const monthOrders = filterCurrentMonthRecords(stockMovements)?.length ?? 0;

    const isTrial = authState?.subscription?.data?.status === 'TRIAL';
    const isValid = authState?.subscription?.isValid;
    const sub_id = authState?.subscription?.data?.subscription?.id;
    const planName = authState?.subscription?.data?.plan?.name ?? '';
    const cycle = authState?.subscription?.data?.subscription?.cycle ?? '';
    const subscriptionStartDate = new Date(authState?.subscription?.data?.subscription?.dateCreated).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) ?? '';
    const subscriptionEndDate = new Date(authState?.subscription?.data?.subscription?.nextDueDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) ?? '';
    const externalReference = authState?.subscription?.data?.subscription?.externalReference;
    let subscriptionOrderId
    if (externalReference) subscriptionOrderId = JSON.parse(externalReference)?.order_id;

    const planTitle = () => {
        if (!isValid) return 'Nenhum plano ativo!';
        if (isTrial) return 'Período de Teste';
        if (cycle === 'MONTHLY') return planName + ' ' + 'Mensal';
        if (cycle === 'YEARLY') return planName + ' ' + 'Anual';
        return planName + ' ' + cycle;
    }

    useEffect(() => {
        const getInvoices = async () => {
            try {
                const response = await api.get(`/transactions/${subscriptionOrderId}`);
                if (response.data.status === 'success') {
                    setInvoiceList(response?.data?.items);
                } else {
                    setInvoiceList([]);
                }
            } catch (error) {
                setInvoiceList([]);
            }
        }
        if (isValid) getInvoices();
    }, []);

    const confirmDeleteHandler = async () => {
        setIsLoading(prev => !prev);
        try {
            await api.post('/asaas/cancel-subscription', { order_id: subscriptionOrderId, sub_id });
            window.location.reload();
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message ?? error);
        }
        setIsLoading(prev => !prev);
    };

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }


    const planHeadStyle = isValid ? undefined : {
        backgroundColor: '#FFB0B4',
        color: '#202124',
        fontWeight: 600,
        fontSize: 18,
        borderBottom: null,
        fontFamily: "Roboto"
    }

    return (
        <>
            <div style={{ display: "flex", gap: 20 }}>
                <IndexBox title={'Produtos Cadastrados'} current={totalProducts} total={qtd_articles} />
                <IndexBox title={'Produtos em Estoque'} current={productsAvailables} total={totalProducts} />
                <IndexBox title={'Controlando Estoque'} current={totalHasStockControl} total={totalProducts} />
                <IndexBox title={'Contas Ativas'} current={totalAccounts} total={qtd_accounts} />
                <IndexBox title={'Vendas do Dia'} current={dayOrders} />
                <IndexBox title={'Vendas do Mês'} current={monthOrders} />
                {/* <IndexBox title={'Endereços Cadastrados'} current={totalLocations} /> */}
            </div>

            <div style={{ height: '1px', backgroundColor: '#E9EBEE', marginTop: 20 }} />

            <div style={{ display: "flex", height: '69%' }}>

                <div style={{ flex: 2, borderRight: '1px solid #E9EBEE', paddingTop: '15px', paddingRight: '18px' }}>
                    <p className="p-title-h3">Plano Atual</p>
                    <DefaultCard
                        title={planTitle()}
                        headStyle={planHeadStyle}
                    >

                        {isValid && (<>
                            <p className="p-plan-details">Início: {subscriptionStartDate}</p>
                            <p className="p-plan-details">{(isTrial ? 'Fim: ' : 'Renovação: ') + (subscriptionEndDate)}</p>
                        </>)}

                        {(planName != 'Ilimitado' || !isValid) && (
                            <DefaultButton
                                label={(isTrial || !isValid) ? 'ASSINAR UM PLANO' : 'FAZER UPGRADE'}
                                onClick={() => navigate('/pricing')}
                                style={{ width: '100%', marginTop: '26px', letterSpacing: '1px' }}
                            />
                        )}

                        {(!isTrial && isValid) && <div className="a-link-cancel"><a onClick={setShowModalDelete}>Cancelar Assinatura</a></div>}

                    </DefaultCard>
                </div>

                <div style={{ flex: 5 }}>
                    {invoiceList.length > 0 && (
                        <div style={{ paddingTop: '15px', paddingLeft: '18px', paddingRight: '18px' }}>
                            <p className="p-title-h3">Faturas</p>
                            {invoiceList.map(x => <InvoiceBox key={x.invoiceUrl} value={x.value} date={x.dueDate} status={x.status} invoiceUrl={x.invoiceUrl} />)}
                        </div>
                    )}
                </div>

                <div style={{ flex: 3 }}>

                </div>

            </div >
            <ModalButtons
                textCancel={"Voltar"}
                textConfirm={"Sim, Cancelar"}
                headerText={"Cancelar assinatura"}
                message={"Você tem certeza que deseja cancelar sua assinatura? Ao cancelar, você perderá os dias restantes de acesso. Além disso, todas as faturas em aberto ou vencidas serão removidas."}
                onCancel={() => setShowModalDelete(false)}
                onConfirm={confirmDeleteHandler}
                show={showModalDelete}
                id={1}
                redConfirm={true}
                icon={trash}
            />
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isLoading} />
        </>
    );

}

export default Main;




const getCurrentDate = () => {
    const now = new Date();
    return { day: now.getDate(), month: now.getMonth() + 1, year: now.getFullYear(), };
};

const filterCurrentMonthRecords = (movements) => {
    const { month, year } = getCurrentDate();
    return movements.filter((movement) => {
        if (movement.external_code) {
            const movementDate = new Date(movement.created_at);
            return (
                movementDate.getMonth() + 1 === month &&
                movementDate.getFullYear() === year
            );
        }
    });
};

const filterCurrentDayRecords = (movements) => {
    const { day, month, year } = getCurrentDate();
    return movements.filter((movement) => {
        if (movement.external_code) {
            const movementDate = new Date(movement.created_at);
            return (
                movementDate.getDate() === day &&
                movementDate.getMonth() + 1 === month &&
                movementDate.getFullYear() === year
            );
        }
    });
};