import React from "react";
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom';
import logo_logistiko from '../../../../Assets/logo_logistiko.svg';
import "../../../../UI/TermsOfUse.scss"


const TermsOfUse = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="header">
                <img src={logo_logistiko} style={{ height: 25, marginTop: 10, marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate("/"); window.location.reload(); }} />
            </div>

            <div className="header-div">
                <h1 className="h1">
                    Termos de Uso
                </h1>
                <h2>
                    Última atualização: 16/05/2024
                </h2>
            </div >
            <Row className="body-div">
                <Col>
                    <p><b>1. Aceitação dos Termos</b><br />
                        Ao acessar e usar o sistema Logistiko, você concorda em cumprir e estar sujeito aos seguintes Termos de Uso. Se você não concorda com qualquer parte destes termos, não deve usar nosso sistema.</p>

                    <p><b>2. Descrição do Serviço</b><br />
                        O Logistiko é um sistema de gestão de estoque que integra com marketplaces, atualizando automaticamente os saldos de estoque em diversas contas de anúncios. Ele oferece funcionalidades de controle de estoque, relatórios, e outras ferramentas para facilitar a gestão de vendas em múltiplas plataformas.</p>

                    <p><b>3. Cadastro e Acesso</b><br />
                        Para utilizar o Logistiko, é necessário criar uma conta fornecendo informações precisas e atualizadas. Você é responsável por manter a confidencialidade das credenciais de sua conta e por todas as atividades que ocorram sob a sua conta.</p>

                    <p><b>4. Uso Adequado</b><br />
                        Você concorda em usar o Logistiko apenas para fins legais e de acordo com os Termos de Uso. Você não pode usar o sistema para qualquer propósito ilegal ou não autorizado.</p>

                    <p><b>5. Pagamentos e Assinaturas</b><br />
                        Os detalhes sobre os planos de assinatura e pagamentos serão fornecidos na seção específica de planos e valores. Você concorda em pagar todas as taxas associadas ao uso do Logistiko conforme descrito nos planos escolhidos.</p>

                    <p><b>6. Modificações no Serviço</b><br />
                        O Logistiko pode, periodicamente, mudar ou atualizar o sistema. Essas alterações podem incluir a adição de novas funcionalidades, a modificação das existentes ou a remoção de partes do sistema. Essas mudanças podem ocorrer com ou sem aviso prévio.</p>

                    <p><b>7. Limitação de Responsabilidade</b><br />
                        Em nenhuma circunstância o Logistiko será responsável por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais resultantes do uso ou da incapacidade de usar o sistema.</p>

                    <p><b>8. Rescisão</b><br />
                        Podemos rescindir ou suspender seu acesso ao Logistiko imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, sem limitação, se você violar os Termos de Uso.</p>

                    <p><b>9. Alterações nos Termos</b><br />
                        O Logistiko pode modificar os Termos de Uso de tempos em tempos. Quaisquer alterações serão notificadas e o uso contínuo do sistema após tais modificações constitui sua aceitação dos novos termos.</p>

                    <p><b>10. Contato</b><br />
                        Se você tiver qualquer dúvida sobre estes Termos de Uso, entre em contato conosco através do canal de suporte.</p>
                </Col>
            </Row>
        </>
    );
};

export default TermsOfUse;
