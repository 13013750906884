import { Form, Input, InputNumber, Select } from "antd";


const { Option } = Select;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    data,
    ...restProps
}) => {
    let inputNode
    if (inputType === 'number') inputNode = <InputNumber style={{ width: '100%' }} />;
    if (inputType === 'dropdown') inputNode = <Select>{data.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}</Select>;
    if (inputType === 'text') inputNode = <Input style={{ width: '100%' }} />;

    let rules = [
        { required: true, message: `Por favor, insira ${title}!` },
    ];

    // Adicionando validação customizada para evitar valores numéricos menores ou iguais a zero
    if (inputType === 'number') {
        rules.push({
            validator: async (_, value) => {
                if (value <= 0) {
                    return Promise.reject(new Error('O valor deve ser maior que zero.'));
                }
            }
        });
    }

    // Validação para campos de texto, para evitar strings vazias ou apenas espaços em branco
    if (inputType === 'text' || inputType === 'dropdown') {
        rules.push({
            validator: async (_, value) => {
                if (!value) {
                    return Promise.reject(new Error('O campo não pode ser vazio.'));
                }
            }
        });
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={rules}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


export default EditableCell;