import React, { useState } from "react";
import { Space, Row, Col } from "antd";
import ModalButtons from "../../Common/Modal/ModalButtons";
import ModalText from "../../Common/Modal/ModalText";
import useMPAccounts from "../../../State/Hooks/useMPAccounts";
import useMarketPlaces from "../../../State/Hooks/useMarketPlaces";
import useAuth from "../../../State/Hooks/useAuth";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultTable from "../../Common/DefaultTable";
import stroke from "../../../../Assets/modal/stroke.svg"
import { ReactComponent as SearchIcon } from "../../../../Assets/search_icon.svg";
import { ReactComponent as Detach } from "../../../../Assets/detach.svg";
import { useEffect } from "react";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import Loading from "../../Common/Loading";
import "../../../../UI/Pages/MPAccounts/MPAccounts.scss"
import DefaultIcon from "../../Common/DefaultIcon";

const MPAccountList = () => {
    const { accounts, addAccount, removeAccount } = useMPAccounts();
    const { authState } = useAuth();
    const { marketplaces } = useMarketPlaces();
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [key, setKey] = useState();
    const [filteredList, setFilteredList] = useState([]);
    const [filter, setFilter] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const onDeleteHandler = (key) => { setShowModalDelete(true); setKey(key); };

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }

    const confirmDeleteHandler = async () => {
        setIsSaving(prev => !prev);
        setShowModalDelete(false);
        try {
            await removeAccount(key);
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message);
        }
        setIsSaving(prev => !prev);
    };

    const meliLoginhandler = async () => {
        setShowModalDelete(false);
        setIsSaving(prev => !prev);
        try {
            await addAccount();
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message);
        }
        setIsSaving(prev => !prev);
    }

    const columns = [
        {
            title: "Nome",
            dataIndex: "metadata",
            render: (_, record) => record.metadata?.first_name + " " + record.metadata?.last_name,
            sorter: (a, b) => a.metadata?.first_name + " " + a.metadata?.last_name?.localeCompare(b.metadata?.last_name),
        },
        {
            title: "Apelido",
            dataIndex: "metadata",
            render: (_, record) => record.metadata?.nickname,
            sorter: (a, b) => a.metadata?.nickname?.localeCompare(b.metadata?.nickname),
        },
        {
            title: "email",
            dataIndex: "metadata",
            render: (_, record) => record.metadata?.email,
            sorter: (a, b) => a.metadata?.email?.localeCompare(b.metadata?.email),
        },
        {
            title: "MarketPlace",
            dataIndex: "marketplace_id",
            render: (marketplace_id) => marketplaces?.find((x) => x.id === marketplace_id)?.name
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (x) => x === true ? "Sincronizado" : "Desvinculado"
        },
        {
            title: " ",
            width: '4%',
            render: (_, record) =>
                <div style={{ display: 'flex' }} >
                    {record.status === true && (<DefaultIcon tooltip={"Desvincular"} icon={<span className="icon-wrapper"><Detach /></span>} onClick={() => onDeleteHandler(record.id)} />)}
                </div >
        },
    ];



    const isButtonActive = () => authState.subscription.data.plan.qtd_accounts > accounts.filter((x) => x.status === true).length;

    //filter
    useEffect(() => {
        let updatedState = [...accounts];
        if (filter.length > 0) updatedState = updatedState.filter((item) =>
            (item.metadata?.first_name + " " + item.metadata?.last_name).toLowerCase().includes(filter.toLowerCase())
            || item?.metadata?.nickname?.toLowerCase().includes(filter.toLowerCase())
            || item?.metadata?.email?.toLowerCase().includes(filter.toLowerCase())
        );
        setFilteredList(updatedState)
    }, [filter, accounts]);


    const onChangeFilter = (event) => setFilter(event.target.value);

    return (
        <div className="list">
            <div className="site-space-compact-wrapper">
                <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                    <Row gutter={24} style={{ justifyContent: "space-between", marginBottom: -20 }}>
                        <Col span={8}>
                            <DefaultInput name="filter" placeHolder="Nome, Apelido ou Email" onChange={onChangeFilter} prefix={<SearchIcon />} />
                        </Col>
                        <Col span={8} />
                        <Col span={2} />
                        <Col span={5} >
                            <DefaultButton disabled={!isButtonActive()} onClick={meliLoginhandler} label={" Cadastrar Conta ML "} />
                        </Col>
                    </Row>
                    <p></p>
                    <DefaultTable scroll={{ x: "100%" }} columns={columns} dataSource={filteredList} rowKey={"id"} />
                </Space>
            </div>
            {showModalDelete && (
                <ModalButtons
                    textCancel={"Cancelar"}
                    textConfirm={"Sim, Desvincular"}
                    headerText={"Desvincular Conta"}
                    message={"Tem certeza que deseja desvincular esta conta? Uma vez desvinculada essa conta não receberá mais atualizações deste sistema."}
                    onCancel={() => setShowModalDelete(false)}
                    onConfirm={confirmDeleteHandler}
                    show={showModalDelete}
                    id={1}
                    redConfirm={true}
                    link={""}
                    icon={stroke}
                />
            )}
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isSaving} />
        </div>
    );
};

export default MPAccountList;
