import React, { useEffect, useState } from "react";
import api from "../../../Api/api";
import { useNavigate } from 'react-router-dom';
import logo_logistiko from '../../../../Assets/logo_logistiko.svg';
import useAuth from "../../../State/Hooks/useAuth";
import DefaultButton from "../../Common/Buttons/DefaultButton"
import PlanBox from "./PlanBox";
import "../../../../UI/Pricing.scss"
import Loading from "../../Common/Loading";
import { jwtDecode } from "jwt-decode";


const PricingList = () => {
    const { authState, tryLocalSignin } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [priceList, setPriceList] = useState([]);
    const navigate = useNavigate();

    const decodedToken = authState?.token ? jwtDecode(authState.token) : null;

    const isValid = authState?.subscription?.isValid;
    const isTrial = authState?.subscription?.data?.status === 'TRIAL';

    const level = isTrial ? 0 : authState?.subscription?.data?.plan?.level;


    useEffect(() => {
        if (isLoading) {
            async function load() {
                try {
                    await tryLocalSignin();
                    const response = await api.get('/plans_prices');
                    if (response?.data?.status === 'success') {
                        setPriceList(response?.data?.items || [])
                    } else {
                        return [];
                    }
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            }

            load();
        }
    }, [isLoading, authState]);


    const submit = async (plan_details) => {
        if (authState.token) {
            navigate('/payment', { state: { plan_details } });
        } else {
            navigate('/register');
        }
    }

    console.log(priceList)

    return (
        <>
            <div className="header">
                <img src={logo_logistiko} style={{ height: 25, marginTop: 10, marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate("/"); window.location.reload(); }} />
                {decodedToken?.email}
            </div>

            <div className="header-div">
                <h1 className="h1">Planos Premium Logistiko!</h1>
                <p className="text">Para começar a vincular estoques no Logistiko, é necessário adquirir</p>
                <p className="text">um dos nossos planos premium. Aproveite recursos avançados</p>
                <p className="text">para otimizar sua gestão de estoque!</p>
                <div className="login-buttons" >
                    {!isLoading && (
                        authState.token
                            ? <h1 className="h1">
                                Escolha seu plano:
                            </h1>
                            : (<>
                                <DefaultButton label={"CRIAR UMA CONTA"} onClick={() => navigate('/register')} />
                                <DefaultButton label={"FAZER LOGIN"} onClick={() => navigate('/')} white={true} />
                            </>)
                    )}

                </div>
            </div>

            <div className="plans">
                {priceList.map(plan => <PlanBox
                    key={plan.price_id}
                    plan={plan}
                    onSubmit={submit}
                    currentLevel={level}
                    isValid={isValid}
                    isAuth={decodedToken?.email ? true : false}
                />)}
            </div>


            <Loading show={isLoading} />

        </>
    );
};

export default PricingList;
