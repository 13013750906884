import { useLocation, useNavigate } from 'react-router-dom';
import logo_logistiko from '../../../../Assets/logo_logistiko.svg';
import DefaultInput from "../../Common/Inputs/DefaultInput";
import { Col, Form, Row } from "antd";
import api from "../../../Api/api";
import { useEffect, useState } from "react";
import DefaultCard from "../../Common/DefaultCard";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import payment_icon from "../../../../Assets/payment_icon.svg";
import return_icon from "../../../../Assets/return_icon.svg";
import arrow_right from "../../../../Assets/arrow_right.svg";
import arrow_left from "../../../../Assets/arrow_left.svg";
import useAuth from '../../../State/Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Loading from '../../Common/Loading';

const Payment = () => {
    const [form] = Form.useForm();
    const [submitReturn, setSubmitReturn] = useState();
    const [ip, setIp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const { plan_details } = location.state || {};
    const { price_id, price_name, plan_name, price } = plan_details || {};
    const { authState } = useAuth();
    const decodedToken = jwtDecode(authState.token);

    const navigate = useNavigate();

    if (!price_id) {
        navigate('/register');
    }

    const validateMonth = (_, value) => {
        if (!value || value < 1 || value > 12) {
            return Promise.reject(new Error('Mês inválido'));
        }
        return Promise.resolve();
    };

    const validateYear = (_, value) => {
        const currentYear = new Date().getFullYear();
        if (!value || value < currentYear) {
            return Promise.reject(new Error('Ano inválido'));
        }
        return Promise.resolve();
    };

    const formatCEP = (value) => {
        if (value && value.length === 8) {
            return `${value.slice(0, 5)}-${value.slice(5, 8)}`;
        }
        return value;
    };

    const handleCepBlur = (e) => {
        const { value } = e.target;
        const formattedValue = formatCEP(value);
        form.setFieldsValue({ postalCode: formattedValue });
    };

    const submit = async (formData) => {
        try {
            setIsLoading(true);
            const response = await api.post(`/subscribe`, { price_id, cardInfo: formData, ip });
            if (response?.data?.status === 'success') {
                setSubmitReturn("success");
            }
        } catch (error) {
            setSubmitReturn("error");
        }
        setIsLoading(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://api.ipify.org?format=json')
            .then(response => setIp(response.data.ip))
            .catch(error => console.error('Erro ao obter o IP: ', error));
    }, []);

    return (
        <>
            <div className="header">
                <img src={logo_logistiko} style={{ height: 25, marginTop: 10, marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate("/"); window.location.reload(); }} />
                {decodedToken?.email}
            </div>

            {
                submitReturn?.length > 0
                    ? (<>
                        <div className="header-div-payment">
                            <p style={{ marginBottom: 1 }}></p>
                            <h1 className="h1">
                                {submitReturn === "success" ? "Pedido finalizado!" : "Ops! Houve um problema com seu pedido!"}
                            </h1>

                            {submitReturn === "success"
                                ? (<>
                                    <p className="text">Seu plano será ativado logo após a confirmação do pagamento! Acesse o sistema </p>
                                    <p className="text">para começar seu gerenciamento de estoque. </p>
                                    <div style={{ marginTop: 55 }}>
                                        <DefaultButton label={"ACESSAR SISTEMA"} sufix={arrow_right} onClick={() => { navigate("/"); window.location.reload(); }} />
                                    </div>
                                </>)
                                : (<>
                                    <p className="text">Tente fazer o pedido novamente e verifique </p>
                                    <p className="text"> os dados de pagamento. </p>
                                    <div style={{ marginTop: 55 }}>
                                        <DefaultButton label={"VOLTAR PARA PLANOS"} prefix={arrow_left} onClick={() => { navigate("/"); window.location.reload(); }} />
                                    </div>
                                </>)
                            }
                        </div>
                    </>)
                    : (<>

                        <div className="header-div-payment">
                            <p style={{ marginBottom: 1 }}></p>
                            <h1 className="h1">Finalize sua compra</h1>
                            <p className="text">Teste grátis de 30 dias na primeira assinatura!</p>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <div style={{ marginTop: -50, width: '80%', display: 'flex', justifyContent: 'center' }}>
                                <Form
                                    name={"payment"}
                                    form={form}
                                    layout="vertical"
                                    onFinish={submit}
                                >
                                    <Row gutter={24}>
                                        <Col span={13}>
                                            <DefaultCard
                                                title={"Adicionar cartão de crédito"}
                                                white
                                                headStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#202124',
                                                    fontWeight: 600,
                                                    fontSize: 18,
                                                    borderBottom: null,
                                                    fontFamily: "Roboto"
                                                }}>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={"number"}
                                                            label={"Número do cartão"}
                                                            rules={[
                                                                { required: true, message: 'Por favor, insira o número do cartão' },
                                                                { min: 16, message: 'Número inválido' }
                                                            ]}>
                                                            <DefaultInput name="number" placeHolder="digite" maxLength={16} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item name="name" label="Nome exato do cartão" rules={[{ required: true, message: "Por favor, insira o nome do cartão" }]}>
                                                            <DefaultInput name="name" type="text" placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={8}>
                                                        <Form.Item name="month" label="Mês" rules={[{ validator: validateMonth }, { required: true, message: " " }, { min: 2, message: '' }]}>
                                                            <DefaultInput name="month" placeHolder="digite" maxLength={2} type="tel" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={8}>
                                                        <Form.Item name="year" label="Ano" rules={[{ validator: validateYear }, { required: true, message: "" },]}>
                                                            <DefaultInput name="year" placeHolder="digite" maxLength={4} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="CVC"
                                                            name="cvc"
                                                            rules={[
                                                                { required: true, message: "Por favor, insira o CVC" },
                                                                { max: 3, min: 3, message: 'CVC inválido' }
                                                            ]}
                                                        >
                                                            <DefaultInput name="cvc" type="tel" maxLength={3} placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                            </DefaultCard>
                                            <p></p>
                                            <DefaultCard
                                                title={"Detalhes da Fatura"}
                                                white
                                                headStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#202124',
                                                    fontWeight: 600,
                                                    fontSize: 18,
                                                    borderBottom: null,
                                                    fontFamily: "Roboto"
                                                }}
                                            >
                                                <Row gutter={24}>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name={"postalCode"}
                                                            label={"CEP"}
                                                            rules={[
                                                                { required: true, message: 'CEP inválido' },
                                                                { pattern: /^\d{5}-\d{3}$/, message: 'CEP inválido' }
                                                            ]}
                                                        >
                                                            <DefaultInput name="postalCode" placeHolder="digite" maxLength={9} onBlur={handleCepBlur} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={16} >
                                                        <Form.Item name="city" label="Cidade">
                                                            <DefaultInput name="city" placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} >
                                                        <Form.Item name="address" label="Endereço">
                                                            <DefaultInput name="address" placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={24}>
                                                    <Col span={6}>
                                                        <Form.Item name="addressNumber" label="Número" rules={[{ required: true, message: 'Número inválido' }]}>
                                                            <DefaultInput name="addressNumber" type="text" placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={18}>
                                                        <Form.Item name="addressComplement" label="Complemento">
                                                            <DefaultInput name="addressComplement" type="text" placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={24}>
                                                    <Col span={12}>
                                                        <Form.Item name="phone" label="Telefone" rules={[{ required: true, message: 'Por favor, insira o telefone' }]}                                            >
                                                            <DefaultInput name="phone" type="tel" maxLength={14} placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item name="mobilePhone" label="Celular" rules={[{ message: 'Por favor, insira o celular.' }]}>
                                                            <DefaultInput name="mobilePhone" type="tel" maxLength={15} placeHolder="digite" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </DefaultCard>
                                        </Col>

                                        <Col span={8}>
                                            <DefaultCard
                                                title="Resumo"
                                                white
                                                headStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    color: '#202124',
                                                    fontWeight: 600,
                                                    fontSize: 18,
                                                    borderBottom: null,
                                                    fontFamily: "Roboto"
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}><strong>Plano:</strong> {plan_name}</p>
                                                    <p style={{ fontSize: 16, fontWeight: 400 }}><strong>Assinatura:</strong> {price_name}</p>
                                                </div>

                                                <div style={{ height: '1px', backgroundColor: '#ccc', margin: '10px 0' }} />

                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    {price_name == "Anual"
                                                        ? <p style={{ fontSize: 16, fontWeight: 500 }}>R$ {(price / 12).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} x 12 meses</p>
                                                        : <p style={{ fontSize: 16, fontWeight: 500 }}>R$ {(price / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                    }
                                                    <p style={{ fontSize: 16, fontWeight: 600 }}>Total: R$ {(price / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                </div>

                                                <p />
                                                <DefaultButton label={"FINALIZAR COMPRA"} htmlType={"submit"} />
                                                <p />

                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', marginBottom: '-10px' }}>
                                                    <img src={payment_icon} style={{ width: '20px', height: '20px' }} />
                                                    <p style={{ fontWeight: 400 }}>Pagamento seguro e protegido</p>
                                                </div>
                                                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', marginTop: '-20px' }}>
                                                    <img src={return_icon} style={{ width: '20px', height: '20px' }} />
                                                    <p style={{ fontWeight: 400 }}>7 dias de reembolso garantido</p>
                                                </div> */}


                                                <div style={{ textAlign: 'left', fontSize: '14px', lineHeight: '1.5', marginTop: '20px' }}>
                                                    <p style={{ fontSize: 12, fontWeight: 400 }}>
                                                        Ao comprar, você aceita os <a href="/termsOfUse" target="_blank" style={{ color: '#1a73e8' }}>Termos de Uso</a> e reconhece a leitura da
                                                        <a href="/privacyPolicy" target="_blank" style={{ color: '#1a73e8' }}> Política de Privacidade</a>
                                                        . Você também concorda com a renovação automática do seu plano {price_name} no valor de R$ {(price / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} que pode ser desativada a qualquer momento pela sua conta.<br />
                                                        Os dados do seu cartão serão salvos para compras futuras e renovações de assinatura.
                                                    </p>
                                                </div>
                                            </DefaultCard>
                                        </Col>
                                    </Row>
                                </Form>


                                <Loading show={isLoading} />
                            </div >
                        </div>

                    </>)
            }
            <p></p>
        </>
    );
};


export default Payment;