import { Link } from "react-router-dom";
import DefaultButton from "./DefaultButton";
import "../../../../UI/Common/Link.scss"

export default function LinkButton({ label, link, disabled, onClick, icon }) {
  return (
    <>
      <Link to={link} className="link">
        <DefaultButton disabled={disabled} label={label} onClick={onClick} prefix={icon} />
      </Link>
    </>
  );
}
