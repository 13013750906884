import { Table } from "antd";
import '../../../UI/Common/DefaultTable.scss'
import { useState } from "react";

export default function DefaultTable({
    components,
    scroll,
    columns,
    dataSource,
    style,
    pagination,
    bordered,
    getRowClassName,
    rowKey
}) {
    let defaultPagination = { pageSize: 10 };
    if (pagination) defaultPagination = { ...defaultPagination, ...pagination };

    const [paginationState, setPaginationState] = useState(defaultPagination);
    const defaultScroll = scroll ? dataSource : { x: "100%" };

    const handleTableChange = (newPagination, filters, sorter) => {
        setPaginationState({ ...paginationState, ...newPagination });
    };

    const rowClassName = getRowClassName ? (record) => getRowClassName(record) : null;


    return (
        <Table
            components={components}
            className="custom-table"
            rowClassName={rowClassName}
            style={style}
            scroll={defaultScroll}
            columns={columns}
            dataSource={dataSource}
            pagination={paginationState}
            bordered={bordered}
            rowKey={rowKey ?? undefined}
            onChange={handleTableChange}
        />
    );
}



