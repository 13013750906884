import DefaultButton from "../../Common/Buttons/DefaultButton";
import "../../../../UI/PlanBox.scss"
import DefaultSwitch from "../../Common/Inputs/DefaultSwitch";
import { useState } from "react";

const PlanBox = ({ plan, onSubmit, currentLevel, isValid, isAuth }) => {
    const [switchValue, setSwitchValue] = useState(false);

    const currentPlan = switchValue ? plan[1] : plan[0];
    const planLevel = currentPlan.level;


    const isButtonDisabled = () => {
        if (!isAuth) return false;
        if (isAuth && isValid) return planLevel <= currentLevel;
        if (isAuth && !isValid) return planLevel < currentLevel;
    };


    return (
        <div className="box">
            <div className="inner-box">
                <h1 className="title">{currentPlan.plan_name}</h1>

                <div className="price-box">
                    <p className="cifra">R$</p>
                    <p className="price">{
                        switchValue
                            ? (currentPlan.price / 12).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : (currentPlan.price / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    }</p>
                </div>
                <p className="period">por mês</p>
                <p className="total">Total: R$ {(currentPlan.price / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>


                <div className="toggle-box">
                    <p>mensal</p>
                    <DefaultSwitch defaultChecked={true} onChange={(isChecked) => setSwitchValue(isChecked)} value={switchValue} />
                    <p>anual</p>
                </div>
            </div>

            <div className="plan-includes">
                <p>O <strong>plano {currentPlan.plan_name}</strong> inclui:</p>
                {currentPlan.metadata?.attributes?.map((feature, index) => (
                    <div key={index}>
                        <span>✔ {feature}</span>
                    </div>
                ))}
                <span>
                    <p style={{ marginTop: 30 }}>Recursos <strong>ilimitados</strong>:</p>
                    <p style={{ fontSize: 14, marginTop: -10 }}>Integração com Marketplaces</p>
                    <p style={{ fontSize: 14, marginTop: -10 }}>Controle de Estoque</p>
                </span>

            </div>

            <div className="button">
                <DefaultButton label={'CONTRATAR'} className={"button"} onClick={() => onSubmit(currentPlan)} disabled={isButtonDisabled()} />
            </div>
        </div>
    );
};

export default PlanBox;
