import React, { useEffect, useState } from "react";
import { Space, Row, Col, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import LinkButton from "../../Common/Buttons/LinkButton";
import ModalText from "../../Common/Modal/ModalText";
import useStockMovements from "../../../State/Hooks/useStockMovements";
import useLocationBalances from "../../../State/Hooks/useLocationBalances";
import useBalances from "../../../State/Hooks/useBalances";
import useMarketPlaces from "../../../State/Hooks/useMarketPlaces";
import DefaultTable from "../../Common/DefaultTable";
import plus_white from "../../../../Assets/plus_white.svg"
import DefaultInput from "../../Common/Inputs/DefaultInput";
import { ReactComponent as SearchIcon } from "../../../../Assets/search_icon.svg";
import DefaultIcon from "../../Common/DefaultIcon";
import DefaultDropdown from "../../Common/Inputs/DefaultDropdown";
import Loading from "../../Common/Loading";
import ReloadButton from "../../Common/Buttons/ReloadButton";
import ModalButtons from "../../Common/Modal/ModalButtons";

const StockMovementList = () => {
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [filter, setFilter] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const [stockOutRecord, setStockOutRecord] = useState(null);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const { stockMovements, consumeStock, reloadStockMovements } = useStockMovements();
    const { loadLocationBalances } = useLocationBalances();
    const { loadBalances } = useBalances();
    const { marketplaces } = useMarketPlaces();
    const { Option } = Select;

    const consumeStockHandler = async ({ stock_out_id }) => {
        setIsLoading(prev => !prev);
        try {
            await consumeStock({ stock_out_id });
            loadLocationBalances();
            loadBalances();
        } catch (error) {
            sendErrorMessage(error);
        }
        setIsLoading(prev => !prev);
    }

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }

    const reloadList = async () => {
        setIsLoading(prev => !prev);
        try {
            await reloadStockMovements();
        } catch (error) {
            sendErrorMessage(`Ops! Não foi possível atualizar a lista. Tente novamente mais tarde!`);
        }
        setIsLoading(prev => !prev);
    }

    const columns = [
        { title: "Tipo", dataIndex: "movement_type", render: (type) => type === 1 ? 'Entrada' : 'Saída', sorter: (a, b) => a.movement_type - b.movement_type },
        { title: "Sku", dataIndex: "sku", sorter: (a, b) => a.sku?.localeCompare(b.sku), },
        { title: "Quantidade", dataIndex: "quantity", sorter: (a, b) => a.quantity - b.quantity, },
        { title: "Nota Fiscal", dataIndex: "document_number", sorter: (a, b) => a.document_number?.localeCompare(b.document_number), },
        { title: "Código Externo", dataIndex: "external_code", sorter: (a, b) => a.external_code?.localeCompare(b.external_code), },
        { title: "Data", dataIndex: "created_at", render: (date) => new Date(date).toLocaleDateString('pt-BR') },
        { title: "Observação", dataIndex: "obs", sorter: (a, b) => a.obs?.localeCompare(b.obs), },
        { title: "Status", dataIndex: "status" },
        { title: "Origem", dataIndex: "marketplace_id", render: (mp_id) => mp_id ? marketplaces.find(mp => mp.id == mp_id)?.name : 'Logistiko' },
        //{ title: "Estornado", dataIndex: "reversed", render: (reversed) => (reversed ? "Sim" : "Não"), },
        //{ title: "Referencia Estorno", dataIndex: "keyReversed", sorter: (a, b) => a.keyReversed.localeCompare(b.keyReversed), },
        {
            title: " ",
            width: '4%',
            render: (_, record) =>
                record.status === 'Reservado' && (
                    <div style={{ display: 'flex' }} >
                        <DefaultIcon tooltip={"Baixar Estoque"} icon={<InboxOutlined />} onClick={() => { setShowModalConfirm(true); setStockOutRecord(record) }} />
                    </div >
                )
        },
    ];

    //filter
    useEffect(() => {
        let updatedState = [...stockMovements];
        if (filter.length > 0) updatedState = updatedState.filter((item) =>
            item?.sku?.toLowerCase().includes(filter.toLowerCase())
            || item?.external_code?.toLowerCase().includes(filter.toLowerCase())
            || item?.document_number?.toLowerCase().includes(filter.toLowerCase())
        );

        if (statusFilter?.length > 0) updatedState = updatedState.filter((item) =>
            item?.status?.toLowerCase().includes(statusFilter.toLowerCase()));

        setFilteredList(updatedState)
    }, [filter, stockMovements, statusFilter]);

    const statusOptions = [{ name: 'Reservado' }, { name: 'Baixado' }];

    const onChangeFilter = (event) => setFilter(event.target.value);

    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row gutter={24} style={{ justifyContent: "space-between", marginBottom: -20 }}>
                    <Col xl={{ span: 8 }}>
                        <DefaultInput name="filter" placeHolder="SKU, Código Externo ou NFe" onChange={onChangeFilter} prefix={<SearchIcon />} />
                    </Col>
                    <Col xl={{ span: 3 }}>
                        <DefaultDropdown placeHolder="Status" optionFilterProp={'label'} onChange={(value) => setStatusFilter(value)}>
                            {statusOptions.map((option) => (
                                <Option key={option.name} label={option.name} value={option.name}>
                                    {option.name}
                                </Option>
                            ))}
                        </DefaultDropdown>
                    </Col>
                    <Col xl={{ span: 3 }}>
                        <ReloadButton onClick={reloadList} />
                    </Col>
                    <Col xl={{ span: 5 }} />
                    <Col xl={{ span: 5 }}>
                        <LinkButton link={`/stockMovementEdit/`} label={"Cadastrar Movimento"} icon={plus_white} />
                    </Col>
                </Row>
                <p></p>
                <DefaultTable scroll={{ x: "100%" }} columns={columns} dataSource={filteredList} />
            </Space>

            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <ModalButtons
                textCancel={"Cancelar"}
                textConfirm={"Confirmar"}
                headerText={"Confirmar Baixa"}
                message={"Após consumir a reserva o movimento não poderá ser estornado!"}
                onCancel={() => { setShowModalConfirm(false); setStockOutRecord(null) }}
                onConfirm={() => { setShowModalConfirm(false); consumeStockHandler(stockOutRecord) }}
                show={showModalConfirm}
                id={1}
            />
            <Loading show={isLoading} />
        </>
    );
};


export default StockMovementList;
