import '../../../../UI/Common/Home.scss'

const IndexBox = ({ title, current, total }) => {
    const percent = (current / total * 100).toFixed(0);

    return (
        <div className='index-box'>

            <p className='p-title-index'>{title}</p>

            {total > 99999
                ? (
                    <>
                        <p className='p-index'>Ilimitado</p>
                        <p className='p-prcent'>-</p>
                    </>
                )
                : (<>
                    {total
                        ? (<p className='p-index'>{current + '/' + total}</p>)
                        : (<p className='p-index'>{current}</p>)}

                    {total
                        ? (<p className='p-prcent'>{percent}%</p>)
                        : (<p className='p-prcent'>-</p>)}
                </>)}


        </div>
    );
}

export default IndexBox;