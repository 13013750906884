import { useEffect, useState } from 'react';
import useAuth from '../../../State/Hooks/useAuth';
import MainRoutes from '../../MainRoutes';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import ResetPasswordScreen from './ResetPasswordScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import PricingList from '../Pricing/PricingList';
import ConfirmedAccount from './ConfirmedAccount';
import VerifyAccount from './VerifyAccount';

const ResolveAuth = () => {
  const { authState, tryLocalSignin } = useAuth();
  const [content, setContent] = useState(null)
  const url = new URL(window.location.href);

  useEffect(() => {

    tryLocalSignin();

    if (authState.token) {
      if (url.pathname.includes("/pricing")) {
        setContent(<PricingList />);
      } else {
        setContent(<MainRoutes />);
      }
    } else {
      if (url.pathname.includes("/forgotPassword")) {
        setContent(<ForgotPasswordScreen />);
      } else if (url.pathname.includes("/resetPassword")) {
        setContent(<ResetPasswordScreen />);
      } else if (url.pathname.includes("/register")) {
        setContent(<RegisterScreen />);
      } else if (url.pathname.includes("/accountConfirmed")) {
        setContent(<ConfirmedAccount />);
      } else if (url.pathname.includes("/account-verify")) {
        setContent(<VerifyAccount />);
      } else {
        setContent(<LoginScreen />);
      }
    }


  }, [authState]);

  return (<>
    {content}
  </>);
};

export default ResolveAuth;