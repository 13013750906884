import { Button } from "antd";
import '../../../../UI/Common/Buttons/DefaultButton.scss'

export default function DefaultButton({ label, disabled, onClick, htmlType, className, style, white, prefix, sufix }) {

    const defaultStyle = {
        width: '100%',
    };

    const defaultClass = white ? "white-button" : "default-button";

    const prefixStyle = {
        marginRight: 10,
    }

    const sufixStyle = {
        marginLeft: 10,
    }

    return (
        <Button
            disabled={disabled ?? false}
            htmlType={htmlType}
            onClick={onClick}
            className={className ?? defaultClass}
            style={style ?? defaultStyle}
        >
            {prefix && (<img src={prefix} style={prefixStyle} />)}
            {label}
            {sufix && (<img src={sufix} style={sufixStyle} />)}
        </Button>
    );
}
