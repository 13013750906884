import { useRecoilValue, useSetRecoilState } from "recoil";
import { marketPlacesState } from "../../State/atom";


const useMarketPlaces = () => {
    const marketplaces = useRecoilValue(marketPlacesState);
    const setMarketPlaces = useSetRecoilState(marketPlacesState);

    return { marketplaces };
}


export default useMarketPlaces;
