import React, { useEffect, useState } from "react";
import { Space, Row, Col } from "antd";
import useBalances from "../../../State/Hooks/useBalances";
import DefaultTable from "../../Common/DefaultTable";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import { ReactComponent as SearchIcon } from "../../../../Assets/search_icon.svg";
import ModalText from "../../Common/Modal/ModalText";
import Loading from "../../Common/Loading";
import ReloadButton from "../../Common/Buttons/ReloadButton";

const BalanceList = () => {
    const [filteredList, setFilteredList] = useState([]);
    const [filter, setFilter] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { balances, loadBalances } = useBalances();
    const [errorMessage, setErrorMessage] = useState('');
    const [showModalError, setShowModalError] = useState(false);


    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }

    const reloadList = async () => {
        setIsLoading(prev => !prev);
        try {
            await loadBalances();
        } catch (error) {
            sendErrorMessage(`Ops! Não foi possível atualizar a lista. Tente novamente mais tarde!`);
        }
        setIsLoading(prev => !prev);
    }


    const columns = [
        { title: "SKU", dataIndex: "sku", sorter: (a, b) => a.sku?.localeCompare(b.sku) },
        { title: "Produto", dataIndex: "article_name", sorter: (a, b) => a.article_name?.localeCompare(b.article_name) },
        { title: "Disponível", dataIndex: "available", sorter: (a, b) => a.reserved - b.reserved },
        { title: "Reservado", dataIndex: "reserved", sorter: (a, b) => a.reserved - b.reserved },
        { title: "Total", dataIndex: "total", sorter: (a, b) => a.total - b.total },
    ];

    //filter
    useEffect(() => {
        let updatedState = [...balances];
        if (filter.length > 0) updatedState = updatedState.filter((item) =>
            item?.sku?.toLowerCase().includes(filter.toLowerCase())
            || item?.article_name?.toLowerCase().includes(filter.toLowerCase())
        );
        setFilteredList(updatedState)
    }, [filter, balances]);
    const onChangeFilter = (event) => setFilter(event.target.value);


    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row gutter={24} style={{ justifyContent: "space-between", marginBottom: -20 }}>
                    <Col span={8}>
                        <DefaultInput name="filter" placeHolder="SKU ou Nome" onChange={onChangeFilter} prefix={<SearchIcon />} />
                    </Col>
                    <Col span={3}>
                        <ReloadButton onClick={reloadList} />
                    </Col>
                    <Col span={13} />
                </Row>
                <p></p>
                <DefaultTable scroll={{ x: "100%" }} columns={columns} dataSource={filteredList} rowKey={"sku"} />
            </Space>
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isLoading} />
        </>
    );
};


export default BalanceList;
