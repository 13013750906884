import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./Pages/Main";
import MainLayout from "./MainLayout";
import ProductForm from "./Pages/Products/ProductForm";
import ProductList from "./Pages/Products/ProductList";
import LocationList from "./Pages/Locations/LocationList";
import AddressForm from "./Pages/Locations/LocationForm";
import MPAccountList from "./Pages/MPAccounts/MPAccountList";
import StockMovementList from "./Pages/StockMovement/StockMovementList";
import StockMovementForm from "./Pages/StockMovement/StockMovementForm";
import LocationBalanceList from "./Pages/StockBalance/LocationBalanceList";
import BalanceList from "./Pages/StockBalance/BalanceList";
import AdList from "./Pages/Ads/AdList";
import Loading from "./Common/Loading";


const MainRoutes = () => {

    return (
        <MainLayout>
            <Routes>
                <Route exact path="/" element={<Suspense fallback={<Loading show={true} />}><Main /></Suspense>} />

                {/* Cadastros */}
                <Route path="/productList" element={<Suspense fallback={<Loading show={true} />}><ProductList /></Suspense>} />
                <Route path="/productEdit/:id?" element={<Suspense fallback={<Loading show={true} />}><ProductForm /></Suspense>} />

                <Route path="/locationList" element={<Suspense fallback={<Loading show={true} />}><LocationList /></Suspense>} />
                <Route path="/locationEdit/:id?" element={<AddressForm />} />

                <Route path="/MPAccountList" element={<Suspense fallback={<Loading show={true} />}><MPAccountList /></Suspense>} />
                <Route path="/adsList" element={<Suspense fallback={<Loading show={true} />}><AdList /></Suspense>} />

                {/* Estoque */}
                <Route path="/stockMovementList" element={<Suspense fallback={<Loading show={true} />}><StockMovementList /></Suspense>} />
                <Route path="/stockMovementEdit/:id?" element={<Suspense fallback={<Loading show={true} />}><StockMovementForm /></Suspense>} />

                <Route path="/balanceList" element={<Suspense fallback={<Loading show={true} />}><BalanceList /></Suspense>} />
                <Route path="/locationBalanceList" element={<Suspense fallback={<Loading show={true} />}><LocationBalanceList /></Suspense>} />

            </Routes>
        </MainLayout >
    );
}

export default MainRoutes;