import {
  CodeSandboxOutlined,
  ContainerOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Layout, Space, Menu, theme, Breadcrumb } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModalButtons from "./Common/Modal/ModalButtons";
import useAuth from "../State/Hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import { Icon } from '@iconify/react';
import logout_icon from "../../Assets/modal/logout_icon.svg"
import "../../UI/MainLayout.scss"


const MainLayout = (props) => {
  const { Header, Content, Sider } = Layout;
  const { authState, signout } = useAuth();
  const decodedToken = jwtDecode(authState.token);
  const location = useLocation();
  const [confirmLogoutModal, setConfirmLogoutModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  function getItem(label, key, icon, title, children) {
    return {
      key,
      icon,
      children,
      label,
      title,
    };
  }

  const navigate = useNavigate();
  const [marginLeftCollapse, setMarginLeftCollapse] = useState("150px");
  const [PaddingLeftCollapse, setPaddingLeftCollapse] = useState("220px");
  const [openKeys, setOpenKeys] = useState(["1"]);

  const [headerText, setHeaderText] = useState("");
  const [breadCrumbItem, setBreadCrumbItem] = useState("");
  const [breadCrumbItemLink, setBreadCrumbItemLink] = useState("");
  const [breadCrumbSubItem, setBreadCrumbSubItem] = useState();

  const items = [
    getItem(<Link to="/">Home</Link>, "1", <HomeOutlined />, "Home"),
    getItem("Cadastros", "2", <ContainerOutlined />, "Cadastros", [
      getItem(<Link to="/productList">Produtos</Link>, "2a", null, "Cadastros"),
      getItem(<Link to="/locationList">Endereços</Link>, "2b", null, "Cadastros"),
      //getItem(<Link to="/marketPlaceList">MarketPlaces</Link>, "2c", null, "Cadastros"),
      getItem(<Link to="/MPAccountList">Contas</Link>, "2d", null, "Cadastros"),
      getItem(<Link to="/adsList">Anúncios</Link>, "2e", null, "Cadastros"),
    ]),
    getItem("Estoque", "3", <CodeSandboxOutlined />, "Estoque", [
      getItem(<Link to="/stockMovementList">Movimentações</Link>, "3a", null, "Estoque"),
      getItem(<Link to="/balanceList">Saldos</Link>, "3b", null, "Estoque"),
      getItem(<Link to="/locationBalanceList">Saldos por Endereço</Link>, "3c", null, "Estoque"),
    ]),
  ];

  function handleBreadcrumb(link, item, subItem) {
    setBreadCrumbItemLink(link);
    setBreadCrumbItem(item);
    setBreadCrumbSubItem(subItem);
  }

  function containsPage(page) {
    return location.pathname.indexOf(page) > -1;
  }

  useEffect(() => {
    //monta breadcrumb pela pagina
    if (containsPage("product")) {
      setHeaderText("Produtos");
      switch (location.pathname) {
        case "/productList":
          handleBreadcrumb("/productList", "Produtos");
          break;
        case "/productEdit/":
          handleBreadcrumb("/productList", "Produtos", "Cadastrar Produto");
          break;
        default:
          if (containsPage("View"))
            handleBreadcrumb("/productList", "Produtos", "Visualizar Produto");
          else if (location.state?.adSku) {
            handleBreadcrumb("/productList", "Produtos", "Cadastrar Produto");
          } else {
            handleBreadcrumb("/productList", "Produtos", "Editar Produto");
          }
          break;
      }
    } else if (containsPage("locationEdit") || containsPage("locationList")) {
      setHeaderText("Endereços de Armazenamento");
      switch (location.pathname) {
        case "/locationList":
          handleBreadcrumb("/locationList", "Endereços");
          break;
        case "/locationEdit/":
          handleBreadcrumb("/locationList", "Endereços", "Cadastrar Endereço");
          break;
        default:
          if (containsPage("View"))
            handleBreadcrumb("/locationList", "Endereços", "Visualizar Endereço");
          else handleBreadcrumb("/locationList", "Endereços", "Editar Endereço");
          break;
      }
    } else if (containsPage("MPAccount")) {
      setHeaderText("Contas");
      switch (location.pathname) {
        case "/MPAccountList":
          handleBreadcrumb("/MPAccountList", "Contas");
          break;
        case "/marketPlaceEdit/":
          handleBreadcrumb("/MPAccountList", "Contas", "Cadastrar Conta");
          break;
        default:
          if (containsPage("View"))
            handleBreadcrumb("/MPAccountList", "Contas", "Visualizar Conta");
          else handleBreadcrumb("/MPAccountList", "Contas", "Editar Conta");
          break;
      }
    } else if (containsPage("ovement")) {
      setHeaderText("Movimentos");
      switch (location.pathname) {
        case "/stockMovementList":
          handleBreadcrumb("/stockMovementList", "Movimentos");
          break;
        case "/stockMovementEdit/":
          handleBreadcrumb("/stockMovementList", "Movimentos", "Cadastrar Movimento");
          break;
        default:
          if (containsPage("View"))
            handleBreadcrumb("/stockMovementList", "Movimentos", "Visualizar Movimento");
          else handleBreadcrumb("/stockMovementList", "Movimentos", "Editar Movimento");
          break;
      }
    } else if (containsPage("balance")) {
      setHeaderText("Saldos");
      switch (location.pathname) {
        case "/balanceList":
          handleBreadcrumb("/balanceList", "Saldos");
          break;
        case "/locationBalanceList":
          handleBreadcrumb("/locationBalanceList", "Saldos por Endereço");
          break;
        default:
          handleBreadcrumb();
          break;
      }
    } else if (containsPage("locationBalanceList")) {
      setHeaderText("Saldos");
      switch (location.pathname) {
        case "/locationBalanceList":
          handleBreadcrumb("/locationBalanceList", "Saldos por Endereço");
          break;
        default:
          handleBreadcrumb();
          break;
      }
    } else if (containsPage("adsList")) {
      setHeaderText("Anúncios");
      switch (location.pathname) {
        case "/adsList":
          handleBreadcrumb("/adsList", "Anúncios");
          break;
        default:
          handleBreadcrumb();
          break;
      }
    } else {
      setHeaderText("Início");
      handleBreadcrumb("/", "Home");
    }
  }, [location.pathname]);

  const rootSubmenuKeys = ["1", "2", "3", "4"];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();



  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      size={[0, 48]}
    >
      <Layout hasSider>
        <Sider
          theme="light"
          breakpoint="sm"
          onCollapse={(collapsed) => {
            if (collapsed === true) {
              setMarginLeftCollapse("30px");
              setPaddingLeftCollapse("100px");
            } else {
              setMarginLeftCollapse("150px");
              setPaddingLeftCollapse("220px");
            }
          }}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
            backgroundColor: "#F2F5F8"
          }}
        >
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}>
            <div className="logo" onClick={() => navigate("/")} style={{ cursor: 'pointer' }} />
            <Menu
              className="menu"
              theme="light"
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={items}
              onOpenChange={onOpenChange}
              openKeys={openKeys}
            />
          </div>
        </Sider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Header
            style={{
              marginLeft: marginLeftCollapse,
              textAlign: "left",
              background: colorBgContainer,
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 32,
              height: 55,
              alignItems: "center"
            }}
          >
            <div className="header">
              {headerText}
            </div>

            <div>
              <div
                style={{
                  // height: "90%",
                  display: "flex",
                  alignContent: "center",
                  cursor: "pointer",
                  backgroundColor: logoutModal ? "#fbfbfb" : "white",
                  minWidth: "210px",
                  // padding: "0 10px",
                  justifyContent: "space-around",
                  // marginBottom: "-10px"
                }}
                onClick={() => setLogoutModal(!logoutModal)}
              >
                <span className="user">
                  {decodedToken.userName}
                </span>
              </div>
              <div
                onClick={() => setConfirmLogoutModal(true)}
                className="logout-button"
                style={{
                  cursor: "pointer",
                  transition: "opacity .3s, visibility .3s",
                  opacity: logoutModal ? 1 : 0,
                  visibility: logoutModal ? "visible" : "hidden"
                }}
              >
                <Icon icon="material-symbols:logout" width="18px" style={{ alignSelf: "center", marginRight: 5 }} />
                <span className="user">
                  Fazer Logout
                </span>
              </div>
            </div>
          </Header>
          <div style={{ height: '1px', backgroundColor: '#E9EBEE' }} />
          <Content className="content" style={{ paddingLeft: PaddingLeftCollapse }}>
            <Breadcrumb separator=">" className="breadcrumb" >
              <Breadcrumb.Item>
                <Link to={breadCrumbItemLink}>{breadCrumbItem}</Link>
              </Breadcrumb.Item>
              {breadCrumbSubItem && (
                <Breadcrumb.Item>{breadCrumbSubItem}</Breadcrumb.Item>
              )}
            </Breadcrumb>
            {props.children}
          </Content>
          <ModalButtons
            textCancel={"Cancelar"}
            textConfirm={"Confirmar"}
            message={"Tem certeza que deseja fazer logout?"}
            onCancel={() => setConfirmLogoutModal(false)}
            onConfirm={signout}
            show={confirmLogoutModal}
            redConfirm={true}
            headerText={"Logout"}
            id={1}
            link={""}
            icon={logout_icon}
          />
        </div>
      </Layout>
    </Space>
  );
};
export default MainLayout;
