import { Col, Image, Row } from "antd";
import logo_logistiko from '../../../../Assets/logo_logistiko.svg'
import '../../../../UI/Common/Background.scss'
import '../../../../UI/Pages/Login/ConfirmedAccount.scss'
import { useEffect, useState } from "react";
import api from "../../../Api/api";
import { useNavigate } from "react-router-dom";

const VerifyAccount = () => {
    const [screenText, setScreenText] = useState('Verificando...');
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const email = params.get('email');
    const code = params.get('code');
    const navigate = useNavigate();

    useEffect(() => {
        verifyAccount();
    }, [])


    const verifyAccount = async () => {
        const response = await api.get(`/account-verify`, { params: { email, code } });
        if (response.data.status === 'success') {
            navigate('/accountConfirmed');
            window.location.reload();
        } else {
            setScreenText('Houve um problema com a confirmação da sua conta. Entre em contato com o suporte!')
        }
    }

    return (
        <div className="background">
            <div className="background-img">
                <Row>
                    <Col>
                        <div className="handle-confirmed-account-screen">
                            <Image src={logo_logistiko} preview={false} />
                            <div className="handle-form-confirmed-account-screen">

                                <h1 className="handle-h1-confirmed-account-screen">
                                    Verificação de conta
                                </h1>

                                <p className="handle-p-confirmed-account-screen" >
                                    {screenText}
                                </p>

                            </div>
                        </div>
                    </Col>
                </Row >
            </div >
        </div >
    );
};

export default VerifyAccount;

