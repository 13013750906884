import { Col, Image, Row } from "antd";
import logo_logistiko from '../../../../Assets/logo_logistiko.svg'
import '../../../../UI/Common/Background.scss'
import '../../../../UI/Pages/Login/ConfirmedAccount.scss'
import useAuth from "../../../State/Hooks/useAuth";
import DefaultButton from "../../Common/Buttons/DefaultButton";

const ConfirmedAccount = () => {

    const { signout } = useAuth();

    return (
        <div className="background">
            <div className="background-img">
                <Row>
                    <Col>
                        <div className="handle-confirmed-account-screen">
                            <Image src={logo_logistiko} preview={false} />
                            <div className="handle-form-confirmed-account-screen">

                                <h1 className="handle-h1-confirmed-account-screen">
                                    E-mail confirmado!
                                </h1>

                                <p className="handle-p-confirmed-account-screen" >
                                    Você acabou de confirmar seu email!
                                    Para ir para a tela de login clique no botão
                                    abaixo.
                                </p>

                                <DefaultButton label={"Fazer Login"} onClick={signout} />

                            </div>
                        </div>
                    </Col>
                </Row >
            </div >
        </div >
    );
};

export default ConfirmedAccount;

