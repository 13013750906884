import { Button, Tooltip } from "antd"


export default function DefaultIcon({ icon, tooltip, onClick, disabled }) {

    return (
        <Tooltip title={tooltip}>
            <Button
                shape="circle"
                icon={icon}
                onClick={onClick}
                disabled={disabled ?? false}
                style={{
                    marginTop: -10,
                    marginBottom: -10
                }}
            />
        </Tooltip>
    )

};