import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Space, Form, Row, Col } from "antd";
import ButtonsCard from "../../Common/Buttons/ButtonsCard";
import useProducts from "../../../State/Hooks/useProducts";
import ModalText from "../../Common/Modal/ModalText";
import EditableCell from "../../Common/Inputs/EditableCell";
import api from "../../../Api/api";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import DefaultCard from "../../Common/DefaultCard";
import DefaultSwitch from "../../Common/Inputs/DefaultSwitch";
import plus from '../../../../Assets/plus.svg';
import DefaultTable from "../../Common/DefaultTable";
import Loading from "../../Common/Loading"
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import DefaultIcon from "../../Common/DefaultIcon";

const ProductForm = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [showModalError, setShowModalError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEditingAttribute, setIsEditingAttribute] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [attributes, setAttributes] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const { addProduct, editProduct, products } = useProducts();
    const location = useLocation();
    const { adSku, adTitle } = location?.state ?? { adSku: null, adTitle: null };


    let product = {};
    if (id) product = products.find((product) => product.id == id);
    if (adSku) product = { sku: adSku, name: adTitle };


    useEffect(() => {
        if (id) {
            const getAttributes = async () => {
                try {
                    const response = await api.get(`/article_attr`, { params: { article_id: +id } });
                    if (response.data.status === 'success') {
                        const updatedItems = response.data.items.map(item => ({ ...item, key: item.id }));
                        setAttributes(updatedItems)
                    }
                } catch (error) {
                    sendErrorMessage(error.response.data.message ?? error.message);
                }
            }
            getAttributes();
        };
    }, []);



    const navigate = useNavigate();

    const saveHandler = async (formData) => {
        setIsSaving(prev => !prev);
        const updatedFormData = { ...formData, attributes };
        try {
            if (id) {
                await editProduct({ ...updatedFormData, id });
            } else {
                await addProduct(updatedFormData);
            }
            navigate("/productList");
        } catch (error) {
            sendErrorMessage(error.response.data.message ?? error.message);
        }
        setIsSaving(prev => !prev);
    };

    const sendErrorMessage = (message) => {
        setErrorMessage(message);
        setShowModalError(true);
    }

    const columns = [
        { title: 'Atributo', dataIndex: 'field', width: '25%', editable: true },
        { title: 'Valor', dataIndex: 'value', width: '15%', editable: true },
        {
            title: " ",
            width: '4%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div style={{ display: 'flex', width: 30, gap: 5 }} >
                        <DefaultIcon tooltip={"Salvar"} icon={<SaveOutlined />} onClick={() => save(record.key)} />
                        <DefaultIcon tooltip={"Cancelar"} icon={<CloseOutlined />} onClick={cancelAttribute} />
                    </div>
                ) : (
                    <div style={{ display: 'flex', width: 30, gap: 5 }} >
                        <DefaultIcon tooltip={"Editar"} icon={<EditOutlined />} onClick={() => edit(record)} disabled={editingKey !== ''} />
                        <DefaultIcon tooltip={"Excluir"} icon={<DeleteOutlined />} onClick={() => deleteAttribute(record)} disabled={editingKey !== ''} />
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const addAttributeHandler = () => {
        const newData = {
            key: Date.now(),
            field: '',
            value: '',
        };
        setAttributes([...attributes, newData]);
        edit(newData);
    };


    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ field: '', value: '', ...record });
        setIsEditingAttribute(true);
        setEditingKey(record.key);
    };

    const cancelAttribute = () => {
        setIsEditingAttribute(false);
        setAttributes(prev => prev.filter(x => x.key !== editingKey));
        setEditingKey('');
    };


    const deleteAttribute = (record) => {
        setIsEditingAttribute(false);
        setAttributes(prev => prev.filter(attr => attr.key !== record.key));
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...attributes];
            const index = newData.findIndex((item) => key === item.key);
            const newItem = {
                key,
                field: row.field,
                value: row.value,
            };

            if (index > -1) {
                newData.splice(index, 1, newItem);
            } else {
                newData.push(newItem);
            }

            setAttributes(newData);
            setEditingKey('');
            setIsEditingAttribute(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    return (
        <Form
            fields={[
                { name: ['has_stock_control'], value: id ? product.has_stock_control : true },
                { name: ['has_expiration_date'], value: id ? product.has_expiration_date : false }
            ]}
            initialValues={product}
            name={id ? "EditProduct" : "AddProduct"}
            form={form}
            layout="vertical"
            onFinish={saveHandler}
        >
            <Space direction="vertical" size="middle" style={{ display: "flex", width: "70%", marginBottom: 16 }}>
                <DefaultCard title={id ? "Editar Produto" : "Novo Produto"} size="large" >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={"sku"} label={"SKU"} rules={[{ required: true, message: 'Campo obrigatório', }]} >
                                <DefaultInput name="sku" placeHolder="Digite..." maxLength={100} disabled={Boolean(id || adSku)} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name={"name"} label={"Nome"} rules={[{ required: true, message: "Campo obrigatório" }]}>
                                <DefaultInput name="name" placeHolder="Digite..." maxLength={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item name={"description"} label={"Descrição"} >
                                <DefaultInput name="description" placeHolder="Digite..." maxLength={250} inputType={"textArea"} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name={"has_stock_control"} label={"Controla estoque?"} rules={[{ required: true, message: "Campo obrigatório" }]}>
                                <DefaultSwitch disabled={false} defaultChecked={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name={"has_expiration_date"} label={"Tem data de expiração?"} rules={[{ required: true, message: "Campo obrigatório" }]}>
                                <DefaultSwitch disabled={true} defaultChecked={false} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <DefaultButton
                        onClick={addAttributeHandler}
                        disabled={isEditingAttribute}
                        label={"ADICIONAR ATRIBUTO"}
                        style={{ marginBottom: 20 }}
                        white={true}
                        prefix={plus}
                    />
                    {attributes?.length > 0 && (
                        <DefaultTable
                            components={{ body: { cell: (props) => <EditableCell {...props} data={attributes} /> } }}
                            bordered
                            dataSource={attributes}
                            columns={mergedColumns}
                            pagination={false}
                            style={{ width: "80%" }}
                            rowKey={"key"}
                        />
                    )}
                </DefaultCard>
            </Space>

            <Form.Item>
                <ButtonsCard id={id} onClickCancel={() => navigate("/productList")} link={"/productList"} disabled={isEditingAttribute} />
            </Form.Item>
            <ModalText
                message={errorMessage}
                show={showModalError}
                onCancel={setShowModalError}
            />
            <Loading show={isSaving} />
        </Form >
    );
};

export default ProductForm;



