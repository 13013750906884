import '../../../../UI/Common/Home.scss'
import { DownloadOutlined } from '@ant-design/icons';
import DefaultIcon from '../DefaultIcon';

const InvoiceBox = ({ value, date, status, invoiceUrl }) => {

    return (
        <div className='invoice-box'>
            <p className='p-value'>R$ {(value / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            <p className='p-date'>{date}</p>
            <p className='p-value'>{status}</p>
            <DefaultIcon tooltip={"Visualizar"} icon={<DownloadOutlined />} onClick={() => window.open(invoiceUrl, '_blank')} />
        </div>
    );
}

export default InvoiceBox;