import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import "../../../../UI/Common/Modal/ModalButtons.scss"

const ModalButtons = ({
  textCancel,
  textConfirm,
  headerText,
  message,
  message2,
  onCancel,
  onConfirm,
  redConfirm,
  show,
  id,
  icon,
  link,
}) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (id) {
      setRedirect(true)
    }
  }, [])

  return (
    <>
      <Modal
        className="modal-buttons"
        centered
        open={show}
        onCancel={onCancel}
        footer={null}
      >
        <div className="header">
          {icon && (<div> <img src={icon} /></div>)}
          {headerText ?? headerText}
        </div>

        <div className="text">
          {message}
          {message2 && (<p>{message2}</p>)}
        </div>

        <div className="btnFooter">
          <Button key="1" className="btnCancel" onClick={onCancel}>
            {textCancel}
          </Button>
          {redirect ?
            <Button key="2" className={redConfirm ? "btnConfirmRed" : "btnConfirmBlue"} type="primary" onClick={onConfirm}>
              {textConfirm}
            </Button>
            :
            <Link to={link}>
              <Button key="2" className={redConfirm ? "btnConfirmRed" : "btnConfirmBlue"} type="primary">
                {textConfirm}
              </Button>
            </Link>
          }
        </div>

      </Modal>
    </>
  );
};
export default ModalButtons;
