import { Col, Form, Image, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../State/Hooks/useAuth";

import logo_logistiko from '../../../../Assets/logo_logistiko.svg'
import '../../../../UI/Common/Background.scss'
import '../../../../UI/Pages/Login/RegisterScreen.scss'
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import Loading from "../../Common/Loading";

const RegisterScreen = () => {
    const { authState, newRegister, resendEmailVerification, clearLoginErrorMessage } = useAuth();
    const [hasSendVerification, setHasSendVerification] = useState(false);
    const [currentEmail, setCurrentEmail] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const onSubtmitHandler = async (values) => {
        setIsLoading(prev => !prev);
        clearLoginErrorMessage();
        const isSuccess = await newRegister(values);
        if (isSuccess) {
            setHasSendVerification(true);
            setCurrentEmail(values.email);
        }
        setIsLoading(prev => !prev);
    }

    const resendEmail = async () => {
        setIsLoading(prev => !prev);
        await resendEmailVerification(currentEmail);
        setIsLoading(prev => !prev);
    }

    const validateEmail = (rule, value, callback) => {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = regexEmail.test(value);

        if (isValid) {
            return callback();
        } else {
            return callback('Campo preenchido incorretamente');
        }
    }

    const validatePasswordComplexity = (_, value) => {
        return new Promise((resolve, reject) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasDigit = /\d/.test(value);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
            const hasMinLength = value && value.length >= 8;

            if (hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar && hasMinLength) {
                resolve();
            } else {
                reject('A senha deve conter pelo menos 8 dígitos, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.');
            }
        });
    };


    const validateEqualPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('As senhas não coincidem!'));
        },
    });




    return (
        <div className="background">
            <div className="background-img">
                <Row>
                    <Col>
                        <div className={hasSendVerification ? "handle-confirm-account-screen" : "handle-register-screen"}>
                            <Image src={logo_logistiko} preview={false} />
                            <div className={hasSendVerification ? "handle-content-register-screen" : "handle-form-register-screen"}>
                                <h1 className={hasSendVerification ? "handle-h1-confirm-screen" : "handle-h1-register-screen"}>{hasSendVerification ? "Confirmar cadastro" : "Cadastrar-se"}</h1>
                                {hasSendVerification ?
                                    (<>
                                        <p className="p-account-confirm">Você recebeu um <strong>e-mail de confirmação!</strong></p>
                                        <p className="p-account-confirm">Para realizar seu login acesse o link contido</p>
                                        <p className="p-account-confirm">neste e-mail.</p>

                                        <p className="p-account-confirm" style={{ fontSize: 12, marginTop: 18 }}>
                                            Não recebeu o e-mail?{' '}
                                            <a href="#" onClick={resendEmail} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                Reenviar
                                            </a>
                                        </p>
                                    </>) :
                                    <Form
                                        title="Cadastrar-se"
                                        name="register"
                                        className="form-register-screen"
                                        initialValues={{ remember: true }}
                                        onFinish={(values) => onSubtmitHandler(values)}
                                        layout="vertical"
                                    >
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item label="Nome" name="name" rules={[{ required: true, message: "Coloque seu nome!" }]}>
                                                    <DefaultInput placeHolder="Digite" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Sobrenome" name="last_name" rules={[{ required: true, message: "Coloque seu sobrenome!" }]}>
                                                    <DefaultInput placeHolder="Digite" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item label="Email" name="email" rules={[{ required: true, message: "Coloque seu email!" }, { validator: validateEmail }]}>
                                                    <DefaultInput prefix={<UserOutlined className="site-form-item-icon" />} placeHolder="exemplo@email.com" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="CPF ou CNPJ" name="cpf_cnpj" rules={[{ required: true, message: "Digite o CNPJ ou CPF" }]}>
                                                    <DefaultInput placeHolder="Digite" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Form.Item label="Senha" name="password" rules={[{ required: true, message: "" }, { validator: validatePasswordComplexity }]}>
                                                    <DefaultInput name="password" placeHolder="********" maxLength={120} inputType={"password"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Repetir senha" name="repeatPassword" rules={[{ required: true, message: "" }, validateEqualPassword]}>
                                                    <DefaultInput name="repeatPassword" placeHolder="********" maxLength={120} inputType={"password"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item>
                                            <Row >
                                                <Col span={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    {authState.loginErrorMessage && (<p style={{ fontSize: 12, color: '#FF3541', marginTop: 5 }}>{authState.loginErrorMessage}</p>)}
                                                    <DefaultButton htmlType="submit" label={"CADASTRAR"} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <p className="handle-p-register-screen">Já tem cadastro?&nbsp;
                                            {<Link to='/' className="handle-link-sign-register-screen" onClick={() => Location.reload()}>Fazer Login</Link>}
                                        </p>
                                    </Form>
                                }
                            </div>
                        </div>
                    </Col>
                </Row >
                <Loading show={isLoading} />
            </div >
        </div >
    );
};

export default RegisterScreen;

