import { useRecoilValue, useSetRecoilState } from "recoil";
import { adsState } from "../atom";
import api from "../../Api/api";

const useAds = () => {
    const ads = useRecoilValue(adsState);
    const setAds = useSetRecoilState(adsState);


    const reloadAds = async () => {
        const response = await api.get("/ads");
        if (response.data.status === 'success') setAds(response.data.items);
    }


    return { ads, reloadAds };
}



export default useAds;
