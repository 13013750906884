import { Col, Form, Image, Row } from "antd";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import useAuth from "../../../State/Hooks/useAuth";

import logo_logistiko from '../../../../Assets/logo_logistiko.svg'
import '../../../../UI/Common/Background.scss'
import '../../../../UI/Pages/Login/LoginScreen.scss'
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultInput from "../../Common/Inputs/DefaultInput";
import Loading from "../../Common/Loading";
import { useState } from "react";

const LoginScreen = () => {
  const { authState, signin, resendEmailVerification, clearLoginErrorMessage } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();

  const onSubmitHandler = async (values) => {
    setIsLoading(prev => !prev);
    clearLoginErrorMessage();
    await signin(values);
    setCurrentEmail(values.email);
    setIsLoading(prev => !prev);
  }

  const resendEmail = async () => {
    setIsLoading(prev => !prev);
    await resendEmailVerification(currentEmail);
    setIsLoading(prev => !prev);
  }

  return (
    <div className="background">
      <div className="background-img">
        <Row>
          <Col>
            <div className="handle-login-screen">
              <Image src={logo_logistiko} preview={false} />
              <div className="handle-form-login-screen">
                <h1 className="handle-h1-login-screen">Faça seu login!</h1>
                <Form
                  title="Faça seu login!"
                  name="normal_login"
                  className="form-login-screen"
                  initialValues={{ remember: true }}
                  onFinish={onSubmitHandler}
                  layout="vertical"
                >
                  <Form.Item label="Email" name="email" /*rules={[{ required: true, message: "Coloque seu email!" }]}*/ >
                    <DefaultInput name="email" prefix={<UserOutlined className="site-form-item-icon" />} placeHolder="exemplo@gmail.com" />
                  </Form.Item>
                  <Form.Item label="Senha" name="password" /*rules={[{ required: true, message: "Informe a senha!" }]}*/ >
                    <DefaultInput name={"password"} placeHolder="******" inputType={"password"} />
                  </Form.Item>
                  <Form.Item>
                    <div className="handle-forgot-password-login-screen">
                      <Link to="/forgotPassword" className="forgot-password-login-screen" onClick={() => Location.reload()}>Esqueceu sua senha?</Link>
                    </div>
                  </Form.Item>
                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
                    {authState.loginErrorMessage && (<p style={{ fontSize: 12, color: '#FF3541', marginBottom: -5 }}>{authState.loginErrorMessage}</p>)}
                    {authState?.loginErrorMessage === "Verifique sua conta pelo link enviado ao seu email!" &&
                      (
                        <p className="p-resend-verification" style={{ fontSize: 12, marginTop: 10, marginBottom: 0 }}>
                          Não recebeu o e-mail?{' '}
                          <a href="#" onClick={resendEmail} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            Reenviar
                          </a>
                        </p>
                      )}
                  </div>
                  <Form.Item>
                    <DefaultButton htmlType={"submit"} label={"Acessar"} />
                  </Form.Item>
                  <p className="handle-p-login-screen">Não tem cadastro?&nbsp;
                    {<Link to='/register' className="handle-link-sign-login-screen" onClick={() => Location.reload()}>Cadastre-se</Link>}
                  </p>
                </Form>
              </div>
            </div>
          </Col>
        </Row >
        <Loading show={isLoading} />
      </div >
    </div >
  );
};

export default LoginScreen;