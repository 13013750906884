import { useRecoilValue, useSetRecoilState } from "recoil";
import { balancesState } from "../atom";
import api from "../../Api/api";

const useBalances = () => {
    const balances = useRecoilValue(balancesState);
    const setBalances = useSetRecoilState(balancesState);

    const loadBalances = async () => {
        const response = await api.get("/balances");
        if (response.data.status === 'success') setBalances(response.data.items);
    }

    return { loadBalances, balances };
}



export default useBalances;
