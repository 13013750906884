import { useRecoilValue, useSetRecoilState } from "recoil";
import { MPAccountsState } from "../atom";
import api from "../../Api/api";

const useMPAccounts = () => {
    const accounts = useRecoilValue(MPAccountsState);
    const setAccounts = useSetRecoilState(MPAccountsState);

    const addAccount = async () => {
        const response = await api.get("/mercado-livre/login");
        window.location.href = response.data.authUrl;
    };

    const removeAccount = async (id) => {
        if (!id) {
            throw new Error('Informe o id da conta');
        }

        const response = await api.delete(`/MPAccounts/${id}`);

        if (response.data.status === 'success') {
            setAccounts(previousState => previousState.map(account => account.id === id ? { ...account, status: false } : account));
        } else {
            throw new Error(response.data.message);
        }
    };

    return { addAccount, removeAccount, accounts };
}



export default useMPAccounts;
