import React from "react";
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom';
import logo_logistiko from '../../../../Assets/logo_logistiko.svg';
import "../../../../UI/PrivacyPolicy.scss"

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="header">
                <img src={logo_logistiko} style={{ height: 25, marginTop: 10, marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate("/"); window.location.reload(); }} />
            </div>

            <div className="header-div">
                <h1 className="h1">
                    Política de Privacidade
                </h1>
                <h2>
                    Última atualização: 16/05/2024
                </h2>
            </div >
            <Row className="body-div">
                <Col>
                    <p><b>1. Introdução</b><br />
                        Esta Política de Privacidade descreve como coletamos, usamos, processamos e divulgamos suas informações, incluindo informações pessoais, em conexão com o uso do sistema Logistiko.</p>

                    <p><b>2. Informações que Coletamos:</b></p>
                    <p><b>• Informações de Cadastro:</b> Quando você cria uma conta no Logistiko, coletamos informações como nome, e-mail, telefone e outras informações necessárias.</p>
                    <p><b>• Informações de Uso:</b> Coletamos informações sobre sua interação com o sistema, incluindo registros de acesso, funcionalidades usadas e preferências de configuração.</p>
                    <p><b>• Dados de Transações:</b> Coletamos dados relacionados às transações realizadas através do Logistiko, como vendas, ajustes de estoque e outros dados relevantes.</p>

                    <p><b>3. Uso das Informações</b></p>
                    <p><b>• Fornecimento do Serviço:</b> Utilizamos suas informações para operar, manter e fornecer todas as funcionalidades do Logistiko.</p>
                    <p><b>• Comunicação:</b> Utilizamos suas informações de contato para enviar comunicações relacionadas ao serviço, como atualizações, alertas e suporte.</p>
                    <p><b>• Melhoria do Serviço:</b> Utilizamos dados agregados para entender e analisar tendências de uso, melhorando continuamente o Logistiko.</p>

                    <p><b>4. Compartilhamento de Informações</b></p>
                    <p><b>• Prestadores de Serviço:</b> Podemos compartilhar suas informações com terceiros que prestam serviços em nosso nome, como provedores de hospedagem e processamento de pagamento.</p>
                    <p><b>• Conformidade Legal:</b> Podemos divulgar suas informações para cumprir com obrigações legais, regulatórias ou judiciais.</p>

                    <p><b>5. Segurança</b><br />
                        Adotamos medidas técnicas e organizacionais para proteger suas informações contra acesso não autorizado, perda, destruição ou alteração, em conformidade com a LGPD.</p>

                    <p><b>6. Retenção de Dados</b><br />
                        Retemos suas informações pelo período necessário para cumprir as finalidades descritas nesta Política de Privacidade, salvo se for exigido um período de retenção mais longo ou permitido por lei.</p>

                    <p><b>7. Seus Direitos</b><br />
                        Você tem o direito de acessar, corrigir, atualizar ou solicitar a exclusão de suas informações pessoais. Para exercer esses direitos, entre em contato conosco através do canal de suporte.</p>

                    <p><b>8. Alterações na Política de Privacidade</b><br />
                        Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos você sobre quaisquer alterações significativas através do sistema ou por outros meios apropriados.</p>

                    <p><b>9. Contato</b><br />
                        Se você tiver qualquer dúvida sobre esta Política de Privacidade, entre em contato conosco através do canal de suporte.</p>
                </Col>
            </Row>
        </>
    );
};

export default PrivacyPolicy;
