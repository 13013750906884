import { Button, Card, Col } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../../UI/Common/Buttons/ButtonsCard.scss"

const ButtonsCard = ({ onClickCancel, id, link, disabled }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (id) {
      setRedirect(true)
    }
  }, [])

  return (
    <>
      <div className="buttonsCard">
        <Col span={24}>
          <Card className="card" size="large">
            {redirect
              ? <Link to={link}>
                <Button className="cancel-button">Cancelar</Button>
              </Link>
              : <Button className="cancel-button" onClick={onClickCancel}>Cancelar</Button>
            }
            <Button
              className="save-button"
              disabled={disabled}
              type="primary"
              htmlType="submit"
              onSubmit={(e) => e.preventDefault()}
            >
              Salvar
            </Button>
          </Card>
        </Col>
      </div>
      <p></p>
    </>
  );
};
export default ButtonsCard;
