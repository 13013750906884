import { Switch } from "antd";
import "../../../../UI/Common/Inputs/DefaultSwitch.scss";

export default function DefaultSwitch({ disabled, defaultChecked, value, onChange }) {

    const handleChange = (isChecked) => {
        onChange && onChange(isChecked);
    };

    return (
        <Switch
            className={value ? 'switch-on-hover' : 'switch-off-hover'}
            style={{ background: value ?? defaultChecked ? '#2290B2' : '#ACB4BA', borderColor: value ?? defaultChecked ? '#2290B2' : '#ACB4BA' }}
            disabled={disabled}
            defaultChecked={value ?? defaultChecked}
            onChange={handleChange}
        />
    );
}
