
import { Button, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import "../../../../UI/Common/Buttons/ReloadButton.scss"

export default function ReloadButton({ onClick }) {
    return (
        <Tooltip title={"Recarregar"}>
            <Button
                icon={<ReloadOutlined />}
                onClick={onClick}
                className="reload-button"
            />
        </Tooltip>
    );
}
